import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  addPage,
  saveAsync,
  selectPages,
  updatePageIndex,
} from "../../../../features/flipbook/editor/flipbookEditorSlice";
import { createPage } from "../../../core/flipbook";
import IconButton from "../../IconButton";

export const AddPage: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const pages = useAppSelector(selectPages);
  const [addingPage, setAddingPage] = useState<boolean>(false);

  const _addPage = async () => {
    if (addingPage) {
      return;
    }

    setAddingPage(true);

    const index = !pages ? 0 : pages.length;
    dispatch(addPage(createPage(index)));
    dispatch(updatePageIndex(index));
    dispatch(saveAsync());

    setAddingPage(false);
  };

  return (
    <IconButton
      iconProps={{ icon: faAdd }}
      onClick={_addPage}
      disabled={addingPage}
      style={{
        height: 50,
        alignSelf: "center",
      }}
    />
  );
};
