import { FunctionComponent } from "react";
import { MenuItemProps, MenuItem as SidebarMenuItem } from "react-pro-sidebar";
import { Link, matchPath, useLocation } from "react-router-dom";

export interface IMenuItemProps
  extends Omit<MenuItemProps, "active" | "routerLink"> {
  to?: string;
  /**
   * A string to match against a URL pathname. May contain `:id`-style segments to indicate placeholders for dynamic parameters. May also end with `/*` to indicate matching the rest of the URL pathname.
   * @default IIconMenuItemProps.to
   */
  path?: string;

  /**
   * Should be `true` if this pattern should match the entire URL pathname.
   */
  end?: boolean | undefined;
}

const MenuItem: FunctionComponent<IMenuItemProps> = ({
  to,
  path = to,
  end = false,
  ...rest
}: IMenuItemProps) => {
  const location = useLocation();

  return (
    <SidebarMenuItem
      {...rest}
      routerLink={to ? <Link to={to} /> : undefined}
      active={
        path
          ? !!matchPath({ path: path, end: end }, location.pathname)
          : undefined
      }
    />
  );
};

export default MenuItem;
