import { FunctionComponent, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  IDrawingState,
  addLine,
  saveAsync,
  selectFlipbook,
  selectLayer,
  selectLayers,
  selectPage,
  updateDrawingState,
} from "../../../../features/flipbook/editor/flipbookEditorSlice";
import DrawableCanvas from "../../DrawableCanvas";
import { Line } from "../../../core/flipbook";
import { useToolbar } from "../../Toolbar";
import { Stage } from "konva/lib/Stage";
import panzoom from "panzoom";

export const FlipbookCanvas: FunctionComponent = () => {
  const flipbook = useAppSelector(selectFlipbook);
  const page = useAppSelector(selectPage);
  const layers = useAppSelector(selectLayers);
  const layer = useAppSelector(selectLayer);
  const { tool } = useToolbar();
  const canvasRef = useRef<Stage>(null);
  const container = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!container.current) {
      return;
    }

    panzoom(container.current, {
      // now all zoom operations will happen based on the center of the screen
      transformOrigin: { x: 0.5, y: 0.5 },
      beforeWheel: (e) => {
        var shouldIgnore = !e.ctrlKey;
        return shouldIgnore;
      },
      beforeMouseDown: () => {
        // E.G. Panning
        return true; // Disabled
      },
      zoomDoubleClickSpeed: 1,
      onDoubleClick: () => {
        return false;
      },
    });
  }, [container]);

  if (!flipbook || !page || !layers || !layer) {
    return null;
  }

  const _handleDraw = (line: Line) => {
    // add to store
    dispatch(addLine(line));

    // persist in firestore
    dispatch(saveAsync());
  };

  const _handleDrawing = (drawingState: IDrawingState) => {
    dispatch(updateDrawingState(drawingState));
  };

  return (
    <div
      className={"flipbook-canvas"}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "var(--bs-gray-200)",
        overflow: "auto",
      }}
    >
      <div
        ref={container}
        role="presentation"
        className="flipbook-canvas-container"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "var(--bs-gray-200)",
          overflow: "hidden",
        }}
      >
        <DrawableCanvas
          ref={canvasRef}
          tool={tool}
          layers={layers}
          selectedLayerIndex={layer.index}
          onDraw={_handleDraw}
          onDrawing={_handleDrawing}
          width={flipbook.size[0]}
          height={flipbook.size[1]}
          style={{
            backgroundColor: "white",
            border: "1px solid var(--bs-gray-500)",
          }}
        />
      </div>
    </div>
  );
};

export default FlipbookCanvas;
