import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useMountEffect } from "../../../../library/core/effect";
import { Flipbook } from "../../../../library/core/flipbook";
import { selectSelectedFlipbookId } from "../../selection/flipbookSelectionSlice";
import {
  fetchFlipbookAsync,
  selectFlipbook,
} from "../../editor/flipbookEditorSlice";

export const useFlipbookPreview: () => {
  loading: boolean;
  selected: string | undefined;
  flipbook: Flipbook | undefined;
} = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const dispatch = useAppDispatch();
  const selected = useAppSelector(selectSelectedFlipbookId);
  const flipbook = useAppSelector(selectFlipbook);

  useMountEffect(async () => {
    setLoading(true);

    if (!selected) {
      return;
    }

    await dispatch(
      fetchFlipbookAsync({
        id: selected,
      })
    );

    setLoading(false);
  });

  return { loading, selected, flipbook };
};
