import GIFEncoder from "gif-encoder-2-browser";
import { IEncoder } from "../../flipbookEncoder.types";
import { GifFlipbookEncoderOptions } from "./GifFlipbookEncoder.types";

export class GifFlipbookEncoder
  implements IEncoder<string, GifFlipbookEncoderOptions>
{
  public async create(
    base64Images: string[],
    width: number,
    height: number,
    ctx: CanvasRenderingContext2D,
    { algorithm }: GifFlipbookEncoderOptions = {}
  ): Promise<string> {
    const encoder = new GIFEncoder(width, height, algorithm);
    encoder.start();
    encoder.setDelay(150);

    await Promise.all(
      base64Images.map(async (base64) => {
        await new Promise<void>((resolve) => {
          const image = new Image();

          image.onload = () => {
            ctx.fillStyle = "#ffffff";
            ctx.fillRect(0, 0, width, height);
            ctx.drawImage(image, 0, 0);
            encoder.addFrame(ctx);
            resolve();
          };

          image.src = base64;
        });
      })
    );

    encoder.finish();
    const buffer = encoder.out.getData();
    return `data:image/gif;base64,${buffer.toString("base64")}`;
  }
}

export default GifFlipbookEncoder;
