import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";

export interface FlipbookSelectionState {
  selected: string | undefined;
}

const initialState: FlipbookSelectionState = {
  selected: undefined,
};

export const flipbookSelectionSlice = createSlice({
  name: "flipbookSelection",
  initialState,
  reducers: {
    unselect: (state) => {
      state.selected = undefined;
    },
    select: (state, action: PayloadAction<string>) => {
      state.selected = action.payload;
    },
  },
});

export const { unselect, select } = flipbookSelectionSlice.actions;

/** SELECTORS */
export const selectSelectedFlipbookId = (state: RootState) =>
  state.flipbookSelection.selected;

export const selectSelectedFlipbookMetadata = (state: RootState) =>
  state.flipbookDashboard.flipbooksMetadata.find(
    (x) => x.id === state.flipbookSelection.selected
  );

export default flipbookSelectionSlice.reducer;
