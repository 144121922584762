/* eslint-disable no-unused-vars */
import { FieldInputProps } from "formik";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import IconButton from "../../IconButton";
import { faShareFromSquare } from "@fortawesome/free-regular-svg-icons";

export interface SizeCustomProps
  extends Pick<
    FieldInputProps<string>,
    "name" | "onBlur" | "onChange" | "value"
  > {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

function EnsureMaxReached(
  [width, height]: [number, number],
  max: number
): [number, number] {
  if (width <= max && height <= max) {
    return [width, height];
  }

  if (width > max && height > max) {
    return [max, max];
  }

  return EnsureMaxReached([--width, --height], max);
}

function EnsureMinReached(
  [width, height]: [number, number],
  min: number
): [number, number] {
  if (width >= min && height >= min) {
    return [width, height];
  }

  if (width < min && height < min) {
    return [min, min];
  }

  return EnsureMinReached([++width, ++height], min);
}

function EnsureLimitsReached(
  size: [number, number],
  min: number,
  max: number
): [number, number] {
  return EnsureMinReached(EnsureMaxReached(size, max), min);
}

export const SizeCustom: FunctionComponent<SizeCustomProps> = ({
  name,
  value,
  setFieldValue,
  ...rest
}) => {
  const [mode, setMode] = useState<"width" | "height" | "both">("both");
  const [size, setSize] = useState<[number, number]>(JSON.parse(value));
  const [keepProportions, setKeepProportions] = useState<boolean>(true);

  useEffect(() => {
    setFieldValue(name, JSON.stringify(size));
  }, [name, setFieldValue, size]);
  return (
    <>
      <Form.Label>Custom</Form.Label>
      <IconButton
        iconProps={{ icon: faShareFromSquare, transform: { rotate: 90 } }}
        onClick={() => {
          setSize([size[1], size[0]]);
        }}
      />
      <Form.Control
        type="number"
        min={1}
        max={10000}
        value={size[0]}
        onChange={(e) => {
          setSize([parseInt(e.target.value), size[1]]);
        }}
        onFocus={() => {
          if (!keepProportions) {
            setMode("width");
          }
        }}
        style={
          mode === "width"
            ? {
                borderColor: "#86b7fe",
              }
            : {}
        }
      />
      <Form.Control
        type="number"
        min={1}
        max={10000}
        value={size[1]}
        onChange={(e) => {
          setSize([size[0], parseInt(e.target.value)]);
        }}
        onFocus={() => {
          if (!keepProportions) {
            setMode("height");
          }
        }}
        style={
          mode === "height"
            ? {
                borderColor: "#86b7fe",
              }
            : {}
        }
      />
      <Form.Range
        min={1}
        max={10000}
        onChange={(e) => {
          const sliderValue = parseInt(e.target.value);

          switch (mode) {
            case "width":
              setSize([sliderValue, size[1]]);
              break;
            case "height":
              setSize([size[0], sliderValue]);
              break;
            case "both": {
              const widthDifference = sliderValue - size[1];
              let width = size[0] + widthDifference;

              const newSize = EnsureLimitsReached(
                [width, sliderValue],
                1,
                10000
              );

              setSize(newSize);
              break;
            }
          }
        }}
        value={mode === "both" || mode === "height" ? size[1] : size[0]}
      />
      <Form.Check
        type="checkbox"
        label="Keep proportions"
        checked={keepProportions}
        onChange={() => {
          var newKeepProportions = !keepProportions;
          setKeepProportions(newKeepProportions);

          if (newKeepProportions) {
            setMode("both");
          }
        }}
      />
      <Form.Control
        {...rest}
        name={name}
        value={JSON.stringify(size)}
        readOnly
        disabled
        hidden
      />
    </>
  );
};

export default SizeCustom;
