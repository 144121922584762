import { FunctionComponent } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useLocation } from "react-router-dom";
import { auth } from "../../../..";
import { IRequireAuthOrRedirectProps } from "../RequireAuthOrRedirect.types";

export const RequireAuthOrRedirect: FunctionComponent<
  IRequireAuthOrRedirectProps
> = ({ children }) => {
  const [user, loading] = useAuthState(auth);
  let location = useLocation();

  if (loading) {
    return null;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <>{children}</>;
};

export default RequireAuthOrRedirect;
