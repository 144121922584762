import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export const CreateFlipbookCard: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => navigate("/flipbooks/create")}
      style={{
        height: "100%",
      }}
    >
      <Card.Img as={CreateFlipbookCardIcon} />
    </Card>
  );
};

const StyledFlipbookCardIcon = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--bs-light);
  }
`;

const CreateFlipbookCardIcon: FunctionComponent = () => {
  return (
    <StyledFlipbookCardIcon>
      <FontAwesomeIcon icon={faPlus} size={"5x"}></FontAwesomeIcon>
    </StyledFlipbookCardIcon>
  );
};

export default CreateFlipbookCard;
