import { FunctionComponent } from "react";
import {
  SubMenu as SidebarSubMenu,
  SubMenuProps,
  useProSidebar,
} from "react-pro-sidebar";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

export interface ISubMenuProps
  extends Omit<SubMenuProps, "active" | "open" | "onClick"> {
  to?: string;
  /**
   * A string to match against a URL pathname. May contain `:id`-style segments to indicate placeholders for dynamic parameters. May also end with `/*` to indicate matching the rest of the URL pathname.
   * @default IIconMenuItemProps.to
   */
  path?: string;

  /**
   * Should be `true` if this pattern should match the entire URL pathname.
   */
  end?: boolean | undefined;
}

const SubMenu: FunctionComponent<ISubMenuProps> = ({
  to,
  path = to,
  end = false,
  ...rest
}: ISubMenuProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { collapsed } = useProSidebar();

  return (
    <SidebarSubMenu
      {...rest}
      active={
        path
          ? !!matchPath({ path: path, end: end }, location.pathname)
          : undefined
      }
      open={
        path
          ? !!matchPath({ path: path, end: false }, location.pathname)
          : undefined
      }
      onClick={(e) => {
        if (!collapsed) {
          if (to) {
            navigate(to);
            e.stopPropagation();
          }

          return;
        }

        if (to) {
          navigate(to);

          if (path) {
            if (!matchPath({ path: path, end: false }, location.pathname)) {
              e.stopPropagation();
            }
          }
        }
      }}
    />
  );
};

export default SubMenu;
