import {
  faArrowLeft,
  faArrowRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { PageOptionsProps } from "../Pages.types";
import { StyledPageOptions } from "./styles/StyledPageOptions";
import PageOptionsItem from "./PageOptionsItem";
import { faCopy, faPaste } from "@fortawesome/free-regular-svg-icons";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  deleteSelectedPage,
  insertPageAtIndex,
  saveAsync,
  selectPage,
  selectPageCount,
  selectPageIndex,
  updatePageIndex,
} from "../../../../features/flipbook/editor/flipbookEditorSlice";
import { Page, createPage } from "../../../core/flipbook";
import {
  clearPageClipboard,
  copyPage,
  selectPageClipboard,
} from "../../../../features/flipbook/clipboard/flipbookClipboardSlice";
import { uuidv4 } from "@firebase/util";

export const PageOptions: React.FunctionComponent<PageOptionsProps> = ({
  onClosePageOptions,
  ...rest
}: PageOptionsProps) => {
  const dispatch = useAppDispatch();
  const index = useAppSelector(selectPageIndex);
  const page = useAppSelector(selectPage);
  const pageCount = useAppSelector(selectPageCount);
  const pageClipboard = useAppSelector(selectPageClipboard);
  const [copyLabel, setCopyLabel] = React.useState<"Copy" | "Copied">("Copy");

  const _insertPageAfter = async () => {
    dispatch(insertPageAtIndex(createPage(index + 1)));
    dispatch(updatePageIndex(index + 1));
    dispatch(saveAsync());
    onClosePageOptions();
  };

  const _insertPageBefore = async () => {
    dispatch(insertPageAtIndex(createPage(index)));
    dispatch(updatePageIndex(index));
    dispatch(saveAsync());
    onClosePageOptions();
  };

  const _deletePage = async () => {
    dispatch(deleteSelectedPage());
    dispatch(updatePageIndex(index - 1));
    dispatch(saveAsync());
    onClosePageOptions();
  };

  const _copyPage = async () => {
    if (!page) {
      return;
    }

    setCopyLabel("Copied");
    dispatch(copyPage(page));

    setTimeout(() => {
      setCopyLabel("Copy");
    }, 5000);
  };

  const _pasteAfter = async () => {
    if (!pageClipboard) {
      return;
    }

    const page: Page = {
      ...pageClipboard,
      id: uuidv4(),
      index: index + 1,
    };

    dispatch(insertPageAtIndex(page));
    dispatch(clearPageClipboard());
    dispatch(updatePageIndex(index + 1));
    dispatch(saveAsync());
    onClosePageOptions();
  };

  const _pasteBefore = async () => {
    if (!pageClipboard) {
      return;
    }

    const page: Page = {
      ...pageClipboard,
      id: uuidv4(),
      index: index,
    };

    dispatch(insertPageAtIndex(page));
    dispatch(clearPageClipboard());
    dispatch(updatePageIndex(index));
    dispatch(saveAsync());
    onClosePageOptions();
  };

  return (
    <StyledPageOptions {...rest}>
      <PageOptionsItem
        icon={faTrash}
        label="Delete"
        disabled={pageCount <= 1}
        onClick={_deletePage}
      />
      <PageOptionsItem
        icon={faArrowRight}
        label="Insert page after"
        onClick={_insertPageAfter}
      />
      <PageOptionsItem
        icon={faArrowLeft}
        label="Insert page before"
        onClick={_insertPageBefore}
      />
      <PageOptionsItem
        icon={faCopy}
        label={copyLabel}
        disabled={!page}
        onClick={_copyPage}
      />
      {pageClipboard ? (
        <>
          <PageOptionsItem
            icon={faPaste}
            label="Paste after"
            onClick={_pasteAfter}
          />
          <PageOptionsItem
            icon={faPaste}
            label="Paste before"
            onClick={_pasteBefore}
          />
        </>
      ) : null}
    </StyledPageOptions>
  );
};

export default PageOptions;
