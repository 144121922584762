import {
  Action,
  ThunkAction,
  combineReducers,
  configureStore,
} from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import flipbookDashboardReducer from "../features/flipbook/dashboard/flipbookDashboardSlice";
import flipbookCreationReducer from "../features/flipbook/creation/flipbookCreationSlice";
import flipbookEditorReducer from "../features/flipbook/editor/flipbookEditorSlice";
import flipbookSelectionReducer from "../features/flipbook/selection/flipbookSelectionSlice";
import flipbookToolbarReducer from "../features/flipbook/toolbar/flipbookToolbarSlice";
import flipbookClipboardReducer from "../features/flipbook/clipboard/flipbookClipboardSlice";

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    "flipbookDashboard",
    "flipbookCreation",
    "flipbookEditor",
    "flipbookToolbar",
    "flipbookClipboard",
  ],
};

const flipbookSelectionPersistConfig = {
  key: "flipbookSelection",
  storage: storage,
};

const rootReducer = combineReducers({
  flipbookCreation: flipbookCreationReducer,
  flipbookDashboard: flipbookDashboardReducer,
  flipbookEditor: flipbookEditorReducer,
  flipbookSelection: persistReducer(
    flipbookSelectionPersistConfig,
    flipbookSelectionReducer
  ),
  flipbookToolbar: flipbookToolbarReducer,
  flipbookClipboard: flipbookClipboardReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/FLUSH",
          "persist/REHYDRATE",
          "persist/PAUSE",
          "persist/PERSIST",
          "persist/PURGE",
          "persist/REGISTER",
        ],
      },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
