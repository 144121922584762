import styled from "styled-components";
import { BUTTON_HEIGHT, BUTTON_WIDTH } from "../../../core/styles";

export const StyledEditorPanelItem = styled.div`
  height: ${BUTTON_HEIGHT};
  width: ${BUTTON_WIDTH};
  flex: 0 0 ${BUTTON_WIDTH};

  & button {
    border: none;
    width: 100%;
    height: 100%;
  }

  &.info {
    justify-content: center;
    align-items: center;
    display: flex;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
    flex: 0 0 100%;
    background: var(--bs-light);
    pointer-events: none;
    user-select: none;

    &.vertical {
      height: 100%;
      text-align: center;
      word-break: break-word;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
  }
`;
