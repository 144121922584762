import { EraserTool, PenTool, Tool } from "./types/tool.types";

export const Pen: PenTool = {
  type: "Pen",
  strokeWidth: 10,
  stroke: "#000000",
};

export const Eraser: EraserTool = {
  type: "Eraser",
  strokeWidth: 10,
  stroke: "#000000",
};

export const AllTools: Tool[] = [Pen, Eraser];
