/* eslint-disable no-unused-vars */
import React from "react";
import ReactDOM from "react-dom/client";
import { persistor, store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { BrowserRouter } from "react-router-dom";
import { getAnalytics } from "firebase/analytics";

import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import "./index.scss";
import { ProSidebarProvider } from "react-pro-sidebar";
import {
  FlipbookEncoderFactory,
  setFlipbookEncoderFactory,
} from "./library/core/flipbookEncoder/FlipbookEncoderFactory";
import GifFlipbookEncoder from "./library/core/flipbookEncoder/encoders/gif/GifFlipbookEncoder";
import { GIF_ENCODER_ID } from "./library/core/flipbookEncoder";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCqnwdaQwRybjc7kepl6Mk6xveLiTVufns",
  authDomain: "flipbook-bff28.firebaseapp.com",
  projectId: "flipbook-bff28",
  storageBucket: "flipbook-bff28.appspot.com",
  messagingSenderId: "245959151122",
  appId: "1:245959151122:web:a82cd79292a66bb7d8d2cb",
  measurementId: "G-F9MZLW6TDR",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);

if (process.env.NODE_ENV === "development") {
  connectAuthEmulator(auth, "http://127.0.0.1:9099", { disableWarnings: true });
  connectFirestoreEmulator(db, "localhost", 8080);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

var flipbookEncoder = new FlipbookEncoderFactory();
flipbookEncoder.register(GIF_ENCODER_ID, new GifFlipbookEncoder());

setFlipbookEncoderFactory(flipbookEncoder);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ProSidebarProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ProSidebarProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
