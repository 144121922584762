import React, { useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  saveAsync,
  selectPages,
  selectSize,
  updatePreview,
} from "../../editor/flipbookEditorSlice";
import IconButton from "../../../../library/controls/IconButton";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { PADDING } from "../../../../library/core/styles";
import { useNavigate } from "react-router-dom";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import {
  GIF_ENCODER_ID,
  GifFlipbookEncoderOptions,
  flipbookEncoderFactory,
} from "../../../../library/core/flipbookEncoder";
import { Download } from "./Download";
import { Page } from "../../../../library/core/flipbook";
import { Stage } from "konva/lib/Stage";
import { Layer } from "konva/lib/Layer";
import { Line } from "konva/lib/shapes/Line";

async function createGif(
  pages: Page[],
  width: number,
  height: number,
  ctx: CanvasRenderingContext2D
): Promise<string> {
  const canvas = new Stage({
    width,
    height,
    container: ".preview-container",
  });

  const images: string[] = [];
  pages.forEach((page) => {
    page.layers.forEach((layer) => {
      var canvasLayer = new Layer({
        width,
        height,
        opacity: layer.opacity,
      });

      layer.lines.forEach((line) => {
        canvasLayer.add(
          new Line({
            points: line.points,
            stroke: line.tool.stroke,
            strokeWidth: line.tool.strokeWidth,
            tension: 0.5,
            lineCap: "round",
            lineJoin: "round",
            globalCompositeOperation:
              line.tool.type === "Eraser" ? "destination-out" : "source-over",
            offsetY: layer.index,
          })
        );

        canvas.add(canvasLayer);
      });
    });

    canvas.draw();

    const imageUrl = canvas.toDataURL();
    images.push(imageUrl);

    canvas.destroyChildren();
  });

  const gif = await flipbookEncoderFactory
    .retrieve<string, GifFlipbookEncoderOptions>(GIF_ENCODER_ID)
    .create(images, width, height, ctx);

  return gif;
}

export const Preview: React.FunctionComponent = () => {
  const pages = useAppSelector(selectPages);
  const [width, height] = useAppSelector(selectSize);
  const ref = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    try {
      if (!pages) {
        return;
      }

      if (!canvasRef.current) {
        return;
      }

      const ctx = canvasRef.current.getContext("2d");

      if (!ctx) {
        return;
      }

      createGif(pages, width, height, ctx)
        .then((gif) => {
          if (!ref.current) {
            return;
          }

          dispatch(
            updatePreview({
              type: "PREVIEW",
              data: gif,
            })
          );

          dispatch(saveAsync());

          if (ref.current.src !== undefined) {
            ref.current.src = gif;
          }
        })
        .catch((e) => {
          console.error(e);
        });
    } catch (e) {
      console.error(e);
    }
  }, [pages, width, height, ref, canvasRef, dispatch]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img ref={ref} alt="" />
      <div className="preview-container" style={{ display: "none" }} />
      <canvas
        ref={canvasRef}
        style={{ display: "none" }}
        width={width}
        height={height}
      />
      <div
        style={{
          position: "absolute",
          left: PADDING,
          bottom: PADDING,
        }}
      >
        <IconButton
          iconProps={{ icon: faHome }}
          onClick={() => {
            navigate("/flipbooks");
          }}
        />
        <IconButton
          iconProps={{ icon: faEdit }}
          onClick={() => {
            navigate("/flipbooks/editor");
          }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          right: PADDING,
          bottom: PADDING,
        }}
      >
        <Download />
      </div>
    </div>
  );
};

export default Preview;
