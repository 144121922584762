import {
  faArrowRightFromBracket,
  faCircleXmark,
  faEraser,
  faPaintBrush,
} from "@fortawesome/free-solid-svg-icons";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ToolbarItem, useToolbar } from "..";
import { SIDEBAR_WIDTH } from "../../../core/styles";
import { IToolbarProps } from "../Toolbar.types";

const PullDown = styled.div`
  position: fixed;
  bottom: 0;
  width: ${SIDEBAR_WIDTH};
`;

export const Toolbar: FunctionComponent<IToolbarProps> = () => {
  const { tool, setTool, setStrokeWidth, setStroke, clear } = useToolbar();
  const navigate = useNavigate();

  return (
    <>
      <ToolbarItem
        iconProps={{ icon: faPaintBrush }}
        selected={tool.type === "Pen"}
        onClick={() => {
          setTool("Pen");
        }}
      >
        <div>Options</div>
        <div>
          <label htmlFor="strokeWidth">Stroke:</label>
          <input
            type="string"
            id="stroke"
            name="stroke"
            defaultValue={tool.stroke}
            onChange={(e) => {
              setStroke(e.target.value);
            }}
          />
        </div>
        <div>
          <label htmlFor="strokeWidth">Stroke Width:</label>

          <input
            type="number"
            id="strokeWidth"
            name="strokeWidth"
            value={tool.strokeWidth}
            onChange={(e) => {
              setStrokeWidth(Number(e.target.value));
            }}
          />
        </div>
      </ToolbarItem>
      <ToolbarItem
        iconProps={{ icon: faEraser }}
        selected={tool.type === "Eraser"}
        onClick={() => {
          setTool("Eraser");
        }}
      >
        <div>Options</div>
        <div>
          <label htmlFor="strokeWidth">Stroke:</label>
          <input
            type="string"
            id="stroke"
            name="stroke"
            defaultValue={tool.stroke}
            onChange={(e) => {
              setStroke(e.target.value);
            }}
          />
        </div>
        <div>
          <label htmlFor="strokeWidth">Stroke Width:</label>
          <input
            type="number"
            id="strokeWidth"
            name="strokeWidth"
            value={tool.strokeWidth}
            onChange={(e) => {
              setStrokeWidth(Number(e.target.value));
            }}
          />
        </div>
      </ToolbarItem>
      <ToolbarItem iconProps={{ icon: faCircleXmark }} onClick={clear} />
      <PullDown>
        <ToolbarItem
          iconProps={{ icon: faArrowRightFromBracket }}
          onClick={() => navigate("/flipbooks")}
        />
      </PullDown>
    </>
  );
};

export default Toolbar;
