import {
  Timestamp,
  addDoc,
  deleteDoc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { FlipbookSharedProps, IFlipbook } from "../FlipbookApi.types";
import {
  Flipbook,
  InitialFlipbook,
  flipbookCol,
  flipbookDoc,
  initialPage,
} from "../../../core/flipbook";

export class FlipbookApi implements IFlipbook {
  private _uid: string;

  constructor({ uid }: FlipbookSharedProps) {
    this._uid = uid;
  }

  public async create(flipbook: InitialFlipbook): Promise<string> {
    try {
      const flipbookRef = await addDoc(flipbookCol(this._uid), {
        ...flipbook,
        created: Timestamp.fromDate(new Date()),
        edited: Timestamp.fromDate(new Date()),
        pages: [initialPage],
        id: "",
      });

      await updateDoc(flipbookRef, {
        id: flipbookRef.id,
      });

      return flipbookRef.id;
    } catch (e) {
      console.error(e); // TODO: Logging
      throw e;
    }
  }

  public async fetch(id: string): Promise<Flipbook | undefined> {
    const flipbookSnapshot = await getDoc(flipbookDoc(this._uid, id));

    if (!flipbookSnapshot.exists()) {
      return undefined;
    }

    const flipbookData = flipbookSnapshot.data();
    if (!flipbookData) {
      return undefined;
    }

    const flipbook: Flipbook = {
      ...flipbookSnapshot.data(),
      created: flipbookData.created.toDate().toISOString(),
      edited: flipbookData.edited.toDate().toISOString(),
    };

    return flipbook;
  }

  public async save(flipbookId: string, flipbook: Partial<Flipbook>) {
    try {
      let flipbookToUpdate = {
        ...flipbook,
        edited: Timestamp.fromDate(new Date()),
      };

      /** Ensure Id and Created are never changed */
      delete flipbookToUpdate.id;
      delete flipbookToUpdate.created;

      await updateDoc(flipbookDoc(this._uid, flipbookId), flipbookToUpdate);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async delete(id: string): Promise<void> {
    await deleteDoc(flipbookDoc(this._uid, id));
  }
}

export default FlipbookApi;
