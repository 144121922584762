import { FunctionComponent } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAppSelector } from "../../../../app/hooks";
import { selectSelectedFlipbookMetadata } from "../../../../features/flipbook/selection/flipbookSelectionSlice";
import FlipbookCard from "../../../controls/FlipbookCard";

export const LastEditedFlipbook: FunctionComponent = () => {
  const selectedFlipbook = useAppSelector(selectSelectedFlipbookMetadata);

  if (!selectedFlipbook) {
    return null;
  }

  return (
    <div>
      <h3>Continue where you left off:</h3>
      <Row xs={1} md={2} lg={3} xl={4} xxl={5} className="g-4">
        <Col>
          <FlipbookCard {...selectedFlipbook} />
        </Col>
      </Row>
    </div>
  );
};

export default LastEditedFlipbook;
