import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { selectFlipbooksMetadata } from "../flipbookDashboardSlice";
import { FunctionComponent } from "react";
import SkeletonFlipbookCards from "./SkeletonFlipbookCards";
import { useAppSelector } from "../../../../app/hooks";
import FlipbookCard, {
  CreateFlipbookCard,
} from "../../../../library/controls/FlipbookCard";

interface IFlipbookCardsProps {
  isFetching: boolean;
  isLoading: boolean;
}

const FlipbookCards: FunctionComponent<IFlipbookCardsProps> = ({
  isFetching,
  isLoading,
}) => {
  const flipbooksMetadata = useAppSelector(selectFlipbooksMetadata);

  return (
    <div className="pt-4">
      <h3>Flipbooks</h3>
      <Row xs={1} md={2} lg={3} xl={4} xxl={5} className="g-4">
        {!isLoading && (
          <Col>
            <CreateFlipbookCard />
          </Col>
        )}
        {flipbooksMetadata.map((flipbook, idx) => (
          <Col key={`flipbook-card-${flipbook.id}-${idx}`}>
            <FlipbookCard {...flipbook} />
          </Col>
        ))}
        {(isFetching || isLoading) && <SkeletonFlipbookCards />}
      </Row>
    </div>
  );
};

export default FlipbookCards;
