import { useEffect, useState } from "react";

export const useMountEffect = (effect: () => void | Promise<void>) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (mounted) {
      return;
    }

    setMounted(true);
    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useMountEffect;
