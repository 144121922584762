import { FunctionComponent } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Canvas from "../../Canvas";
import { IPageProps } from "../Pages.types";
import {
  selectPageIndex,
  selectSize,
  updatePageIndex,
} from "../../../../features/flipbook/editor/flipbookEditorSlice";
import { StyledPage } from "./styles/StyledPage";
import PageOptions from "./PageOptions";
import React from "react";
import useOnClickOutside from "../../../core/click";

const SCALE = 0.075;

const StyledCanvas = styled(Canvas)`
  border: 1px solid black;
`;

export const Page: FunctionComponent<IPageProps> = ({
  index,
  ...rest
}: IPageProps) => {
  const dispatch = useAppDispatch();
  const [width, height] = useAppSelector(selectSize);
  const pageIndex = useAppSelector(selectPageIndex);
  const ref = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>();
  useOnClickOutside(
    ref,
    React.useCallback(() => setIsOpen(false), [setIsOpen])
  );

  return (
    <StyledPage ref={ref}>
      <StyledCanvas
        {...rest}
        className="page"
        width={width * SCALE}
        height={height * SCALE}
        scale={{
          x: SCALE,
          y: SCALE,
        }}
        onClick={() => {
          if (index === pageIndex) {
            setIsOpen(!isOpen);
            return;
          }

          dispatch(updatePageIndex(index));
        }}
      />
      {isOpen ? (
        <PageOptions
          {...rest}
          index={index}
          onClosePageOptions={() => {
            setIsOpen(false);
          }}
        />
      ) : null}
    </StyledPage>
  );
};

export default Page;
