import React from "react";
import useOnClickOutside from "../../../core/click";
import IconButton from "../../IconButton/components/IconButton";
import { IEditorPanelItemProps } from "../EditorPanel.types";
import { StyledEditorPanelItem } from "../styles/StyledEditorPanelItem";
import EditorPanelItemOptions from "./EditorPanelItemOptions";

// TODO: A WAY OF LETTING THE USER KNOW THERE IS OPTIONS MENU

export const EditorPanelItem: React.FunctionComponent<
  IEditorPanelItemProps
> = ({ children, location, onClick, ...rest }: IEditorPanelItemProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>();
  useOnClickOutside(
    ref,
    React.useCallback(() => setIsOpen(false), [setIsOpen])
  );

  return (
    <StyledEditorPanelItem ref={ref}>
      <IconButton
        {...rest}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }

          setIsOpen(!isOpen);
        }}
      />
      {isOpen && children ? (
        <EditorPanelItemOptions location={location}>
          {children}
        </EditorPanelItemOptions>
      ) : null}
    </StyledEditorPanelItem>
  );
};

export default EditorPanelItem;
