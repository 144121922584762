import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { IIconButtonProps } from "../../IconButton/IconButton.types";

function IconButtonRef(
  { iconProps, children, ...rest }: IIconButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <Button ref={ref} {...rest}>
      <FontAwesomeIcon {...iconProps} /> {children}
    </Button>
  );
}

export const IconButton = React.forwardRef(IconButtonRef);

export default IconButton;
