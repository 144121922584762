import { FunctionComponent } from "react";
import { MenuItemProps, useProSidebar } from "react-pro-sidebar";
import { StyledStaticMenuItem } from "./styles/StyledStaticMenuItem";

export interface IStaticMenuItemProps
  extends Omit<MenuItemProps, "active" | "routerLink" | "disabled"> {
  /**
   * reduces the padding when collapsed, perfect for tight squeezes.
   * @default ```false```
   */
  collapsedReducePadding?: boolean;
}

const StaticMenuItem: FunctionComponent<IStaticMenuItemProps> = ({
  collapsedReducePadding,
  ...rest
}: IStaticMenuItemProps) => {
  const { collapsed } = useProSidebar();

  return (
    <StyledStaticMenuItem
      {...rest}
      collapsed={collapsed}
      collapsedReducePadding={collapsedReducePadding}
    />
  );
};

export default StaticMenuItem;
