import { RefObject, useLayoutEffect, useState } from "react";

export function useElementResize(
  ref: RefObject<HTMLElement>
): [number, number] {
  const [size, setSize] = useState<[number, number]>([0, 0]);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (!ref.current) {
        return;
      }

      setSize([ref.current?.clientWidth, ref.current?.clientHeight]);
    };

    const observer = new ResizeObserver(handleResize);
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return size;
}

export default useElementResize;
