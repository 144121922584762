import { useEffect, useState } from "react";
import { IInfiniteScrollOptions } from "./scroll.types";

export const useInfiniteScroll = (
  loaderRef: React.RefObject<Element>,
  callback: () => void | Promise<void>,
  options?: IInfiniteScrollOptions
) => {
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const option = {
      root: null,
      threshold: 0.5,
    };

    const handleObserver = (options?: IInfiniteScrollOptions) => {
      return async (entries: IntersectionObserverEntry[]) => {
        if (options?.disabled) {
          return;
        }

        const target = entries[0];

        if (target.isIntersecting) {
          setIsFetching(true);
          await callback();
          setIsFetching(false);
        }
      };
    };

    const observer = new IntersectionObserver(handleObserver(options), option);
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderRef, options?.disabled]);

  return isFetching;
};

export default useInfiniteScroll;
