import {
  Encoder,
  IEncoder,
  IFlipbookEncoderFactory,
} from "./flipbookEncoder.types";

export let flipbookEncoderFactory: IFlipbookEncoderFactory;

export function setFlipbookEncoderFactory(
  encoderFactory: IFlipbookEncoderFactory
) {
  flipbookEncoderFactory = encoderFactory;
}

export function resetFlipbookEncoderFactory() {
  flipbookEncoderFactory = undefined as unknown as IFlipbookEncoderFactory;
}

export class FlipbookEncoderFactory implements IFlipbookEncoderFactory {
  private _encoder: Encoder<any, any> = {};

  public register<TOutput, TOptions>(
    id: string,
    encoder: IEncoder<TOutput, TOptions>
  ): void {
    this._encoder[id] = encoder;
  }

  public retrieve<TOutput, TOptions>(id: string): IEncoder<TOutput, TOptions> {
    const encoder = this._encoder[id] as IEncoder<TOutput, TOptions>;

    if (!encoder) {
      throw new Error("Encoder does not exist with the id " + id);
    }

    return encoder;
  }
}
