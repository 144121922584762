import styled from "styled-components";
import { PADDING, SIDEBAR_WIDTH } from "../../../core/styles";
import { EditorLocation } from "../EditorPanel.types";

export const StyledEditorPanel = styled.div<{
  location: EditorLocation;
  isDrawing: boolean;
}>`
  position: absolute;

  ${({ isDrawing }) => {
    if (!isDrawing) {
      return ``;
    }

    return `
        pointer-events: none;
    `;
  }};

  ${({ location }) => {
    switch (location) {
      case "TOP_RIGHT":
        return `top: ${PADDING}; right: ${PADDING};`;
      case "BOTTOM_LEFT":
        return `bottom: ${PADDING}; 
        left: calc(${SIDEBAR_WIDTH} + ${PADDING});
        display: inline-flex;`;
      case "BOTTOM_RIGHT":
        return `bottom: ${PADDING}; right: ${PADDING};`;
      default:
        return ``;
    }
  }}

  > * {
    position: relative;
    z-index: 100;
  }
`;
