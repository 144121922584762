import { FunctionComponent } from "react";
import { Form, Row } from "react-bootstrap";
import { FormCheckInputProps } from "react-bootstrap/esm/FormCheckInput";

interface Preset {
  label: string;
  desc?: string;
  value: string;
}

const presets: Preset[] = [
  {
    label: "2550 x 3300",
    desc: "Letter 8.5 x 11",
    value: JSON.stringify([2550, 3300]),
  },
  { label: "2480 x 3508", desc: "A4", value: JSON.stringify([2480, 3508]) },
  { label: "1748 x 2480", desc: "A5", value: JSON.stringify([1748, 2480]) },
  { label: "1200 x 1800", desc: "4 x 6", value: JSON.stringify([1200, 1800]) },
  { label: "1500 x 2100", desc: "5 x 7", value: JSON.stringify([1500, 2100]) },
  { label: "2400 x 3000", desc: "8 x 10", value: JSON.stringify([2400, 3000]) },
  { label: "1080 x 1080", desc: "Square", value: JSON.stringify([1080, 1080]) },
  { label: "800 x 600", value: JSON.stringify([800, 600]) },
  { label: "1024 x 768", value: JSON.stringify([1024, 768]) },
  { label: "1280 x 1024", value: JSON.stringify([1280, 1024]) },
  { label: "1600 x 1200", value: JSON.stringify([1600, 1200]) },
  { label: "1280 x 720", desc: "HD 720p", value: JSON.stringify([1280, 720]) },
  { label: "1920 x 1080", desc: "HD", value: JSON.stringify([1920, 1080]) },
  { label: "2560 x 1440", desc: "QHD", value: JSON.stringify([2560, 1440]) },
  { label: "2048 x 1556", desc: "2K", value: JSON.stringify([2048, 1556]) },
  { label: "3480 x 2160", desc: "4K", value: JSON.stringify([3480, 2160]) },
  {
    label: "5120 x 1440",
    desc: "Dual QHD",
    value: JSON.stringify([5120, 1440]),
  },
];

export interface SizePresetsProps extends FormCheckInputProps {}

export const SizePresets: FunctionComponent<SizePresetsProps> = ({
  type,
  ...rest
}) => {
  return (
    <>
      <Form.Label>Presets</Form.Label>
      <Row>
        <div style={{ maxHeight: 350, overflow: "auto", padding: "1em" }}>
          {presets.map(({ label, desc, value }) => {
            return (
              <Form.Check key={label}>
                <Form.Check.Input type={type} value={value} {...rest} />
                <Form.Check.Label>{label}</Form.Check.Label>
                <Form.Text style={{ display: "block" }}>{desc}</Form.Text>
              </Form.Check>
            );
          })}
        </div>
      </Row>
    </>
  );
};

export default SizePresets;
