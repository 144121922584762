import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  collection,
  doc,
} from "firebase/firestore";
import { db } from "../../..";
import {
  FirestoreFlipbook,
  FirestoreFlipbookMetadata,
} from "./types/flipbook.types";

const createCollection = <T = DocumentData>(
  path: string,
  ...pathSegments: string[]
) => {
  return collection(db, path, ...pathSegments) as CollectionReference<T>;
};

const createDocument = <T = DocumentData>(
  path: string,
  ...pathSegments: string[]
) => {
  return doc(db, path, ...pathSegments) as DocumentReference<T>;
};

const USERS = "users";
const FLIPBOOKS = "flipbooks";

// Collections

export const flipbooksMetadataCol = (uid: string) =>
  createCollection<FirestoreFlipbookMetadata>(USERS, uid, FLIPBOOKS);
export const flipbookCol = (uid: string) =>
  createCollection<FirestoreFlipbook>(USERS, uid, FLIPBOOKS);

// Documents

export const flipbookDoc = (uid: string, id: string) =>
  createDocument<FirestoreFlipbook>(USERS, uid, FLIPBOOKS, id);
