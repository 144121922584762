import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { flipbookApi } from "../../../library/apis";
import {
  ColourBackground,
  Flipbook,
  FlipbookPreview,
  ImageBackground,
  Layer,
  Line,
  Page,
} from "../../../library/core/flipbook";

export interface IDrawingState {
  isDrawing: boolean;
}

export interface FlipbookEditorState {
  status: "idle" | "loading" | "loaded" | "failed";

  flipbook: Flipbook | undefined;
  pageIndex: number;
  layerIndex: number;
  isSaving: boolean;
  drawingState: IDrawingState;
}

export const fetchFlipbookAsync = createAsyncThunk<
  Flipbook | undefined,
  {
    id: string;
  },
  { state: RootState }
>(
  "flipbookEditor/fetchFlipbook",
  async ({ id }): Promise<Flipbook | undefined> => {
    const response = await flipbookApi.flipbook.fetch(id);
    return response;
  }
);

export const saveAsync = createAsyncThunk<void, void, { state: RootState }>(
  "flipbookEditor/",
  async (_, { getState }): Promise<void> => {
    const flipbook = getState().flipbookEditor.flipbook;

    if (!flipbook) {
      return;
    }

    await flipbookApi.flipbook.save(flipbook.id, flipbook);
    return;
  }
);

const initialState: FlipbookEditorState = {
  status: "idle",
  flipbook: undefined,
  pageIndex: 0,
  layerIndex: 0,
  isSaving: false,
  drawingState: { isDrawing: false },
};

export const flipbookEditorSlice = createSlice({
  name: "flipbookEditor",
  initialState,
  reducers: {
    updateDrawingState: (
      state: FlipbookEditorState,
      action: PayloadAction<IDrawingState>
    ) => {
      state.drawingState = action.payload;
    },
    updateSize: (
      state: FlipbookEditorState,
      action: PayloadAction<[number, number]>
    ) => {
      if (!state.flipbook) {
        return;
      }

      state.flipbook.size = action.payload;
    },
    updatePageIndex: (
      state: FlipbookEditorState,
      action: PayloadAction<number>
    ) => {
      state.pageIndex = action.payload;

      const layer = state.flipbook?.pages[action.payload].layers.length;
      state.layerIndex = layer ? layer - 1 : 0;
    },
    updateLayerIndex: (
      state: FlipbookEditorState,
      action: PayloadAction<number>
    ) => {
      state.layerIndex = action.payload;
    },
    updateLayer: (
      state: FlipbookEditorState,
      action: PayloadAction<Partial<Layer>>
    ) => {
      if (!state.flipbook) {
        return;
      }

      state.flipbook.pages[state.pageIndex].layers[state.layerIndex] = {
        ...state.flipbook.pages[state.pageIndex].layers[state.layerIndex],
        ...action.payload,
      };
    },
    addPage: (state: FlipbookEditorState, action: PayloadAction<Page>) => {
      if (!state.flipbook) {
        return;
      }

      state.flipbook.pages = [...state.flipbook.pages, action.payload];
    },
    insertPageAtIndex: (
      state: FlipbookEditorState,
      action: PayloadAction<Page>
    ) => {
      if (!state.flipbook) {
        return;
      }

      // Edge case when no existing pages exist
      if (state.flipbook.pages.length === 0) {
        state.flipbook.pages = [{ ...action.payload, index: 0 }];
        return;
      }

      const before = state.flipbook.pages.slice(0, action.payload.index);
      const after = state.flipbook.pages
        .slice(action.payload.index, state.flipbook.pages.length)
        .map((x) => ({
          ...x,
          index: x.index + 1, // Increment the index of all after pages
        }));

      state.flipbook.pages = [...before, action.payload, ...after];
    },
    addLayer: (state: FlipbookEditorState, action: PayloadAction<Layer>) => {
      if (!state.flipbook) {
        return;
      }

      const layers = state.flipbook.pages[state.pageIndex].layers;

      state.flipbook.pages[state.pageIndex].layers = [
        ...layers,
        action.payload,
      ];
    },
    addLine: (state: FlipbookEditorState, action: PayloadAction<Line>) => {
      if (!state.flipbook) {
        return;
      }

      const lines =
        state.flipbook.pages[state.pageIndex].layers[state.layerIndex].lines;
      state.flipbook.pages[state.pageIndex].layers[state.layerIndex].lines = [
        ...lines,
        action.payload,
      ];
    },
    updatePreview: (
      state: FlipbookEditorState,
      action: PayloadAction<
        ColourBackground | ImageBackground | FlipbookPreview
      >
    ) => {
      if (!state.flipbook) {
        return;
      }

      if (action.payload === undefined) {
        return;
      }

      state.flipbook.preview = action.payload;
    },
    deleteSelectedPage: (state: FlipbookEditorState) => {
      if (!state.flipbook) {
        return;
      }

      const selectedPage = state.flipbook.pages[state.pageIndex];

      if (!selectedPage) {
        return;
      }

      state.flipbook.pages = state.flipbook.pages
        .filter((x) => x.id !== selectedPage.id)
        .map((page, i) => ({
          ...page,
          index: i, // Recalculate indexes
        }));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFlipbookAsync.pending, (state) => {
        state.status = "loading";
        state.flipbook = undefined;
      })
      .addCase(fetchFlipbookAsync.fulfilled, (state, action) => {
        state.status = "loaded";
        state.flipbook = action.payload;
      })
      .addCase(fetchFlipbookAsync.rejected, (state) => {
        state.status = "failed";
        state.flipbook = undefined;
      })
      .addCase(saveAsync.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(saveAsync.fulfilled, (state) => {
        state.isSaving = false;
      })
      .addCase(saveAsync.rejected, (state) => {
        state.status = "failed";
        state.isSaving = false;
      });
  },
});

export const {
  addPage,
  insertPageAtIndex,
  addLayer,
  addLine,
  deleteSelectedPage,
  updateDrawingState,
  updatePageIndex,
  updateLayerIndex,
  updateLayer,
  updateSize,
  updatePreview,
} = flipbookEditorSlice.actions;

/** SELECTORS */
export const selectFlipbook = (state: RootState): Flipbook | undefined =>
  state.flipbookEditor.flipbook;

export const selectSize = (state: RootState): [number, number] => {
  if (!state.flipbookEditor.flipbook) {
    return [0, 0];
  }

  return state.flipbookEditor.flipbook.size;
};

export const selectPages = (state: RootState): Page[] | undefined =>
  selectFlipbook(state)?.pages;

export const selectPageCount = (state: RootState): number => {
  const pages = selectFlipbook(state)?.pages;

  if (!pages) {
    return 0;
  }

  return pages.length;
};

export const selectPage = (state: RootState): Page | undefined =>
  selectFlipbook(state)?.pages[state.flipbookEditor.pageIndex];

export const selectLayers = (state: RootState): Layer[] | undefined =>
  selectPage(state)?.layers;

export const selectLayer = (state: RootState): Layer | undefined =>
  selectPage(state)?.layers[state.flipbookEditor.layerIndex];

export const selectDrawingState = (state: RootState): IDrawingState =>
  state.flipbookEditor.drawingState;

export const selectPageIndex = (state: RootState): number =>
  state.flipbookEditor.pageIndex;

export const selectLayerIndex = (state: RootState): number =>
  state.flipbookEditor.layerIndex;

export const selectPreview = (
  state: RootState
): ColourBackground | ImageBackground | FlipbookPreview => {
  if (!state.flipbookEditor.flipbook) {
    return {
      type: "COLOUR",
      colour: "#FFFFFF",
    };
  }

  return state.flipbookEditor.flipbook.preview;
};

export default flipbookEditorSlice.reducer;
