import { FunctionComponent, ReactNode, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../..";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../app/hooks";
import { select } from "../../selection/flipbookSelectionSlice";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { SchemaOf, object, string } from "yup";
import {
  ColourBackground,
  FlipbookPreview,
  ImageBackground,
  InitialFlipbook,
} from "../../../../library/core/flipbook/types/flipbook.types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { flipbookApi } from "../../../../library/apis";
import { SizePresets } from "../../../../library/controls/FormControls";
import { Col } from "react-bootstrap";
import SizeCustom from "../../../../library/controls/FormControls/components/SizeCustom";

export interface Size {
  type: "screen" | "custom" | "presets";
  value: string;
}

interface FormValues {
  name: string;
  size: Size;
  preview: ColourBackground | ImageBackground | FlipbookPreview;
}

const FlipbookCreateSchema: SchemaOf<FormValues> = object({
  name: string().required("Required"),
  preview: object(),
  size: object(),
});

const FlipbookCreate: FunctionComponent = () => {
  const [user] = useAuthState(auth);
  const [formError, setFormError] = useState<ReactNode>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  if (!user) {
    return null;
  }

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setFormError(null);

      const flipbook: InitialFlipbook = {
        name: values.name,
        preview: values.preview,
        size: JSON.parse(values.size.value),
      };

      const id = await flipbookApi.flipbook.create(flipbook);

      dispatch(select(id));
      navigate("/flipbooks/editor");
    } catch (e) {
      setFormError(
        <div>
          An error occurred trying to create your flipbook... Please try again
          later or contact support.
        </div>
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <Row>
        <h1>Flipbook creation</h1>

        {formError && <Alert variant="danger">{formError}</Alert>}

        <Formik<FormValues>
          initialValues={{
            name: "",
            preview: {
              type: "COLOUR",
              colour: "#FFFFFF",
            },
            size: {
              type: "screen",
              value: JSON.stringify([screen.width, screen.height]),
            },
          }}
          validationSchema={FlipbookCreateSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <FloatingLabel label="Flipbook Name">
                    <Form.Control
                      type="text"
                      placeholder="Flipbook Name"
                      size="sm"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      isInvalid={touched.name && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Size</Form.Label>
                  <Form.Check>
                    <Form.Check.Input
                      type="radio"
                      name="size.type"
                      onChange={(e) => {
                        if (e.target.value === "screen") {
                          values.size.value = JSON.stringify([
                            screen.width,
                            screen.height,
                          ]);
                        }

                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      value="screen"
                      checked={values.size.type === "screen"}
                      isInvalid={touched.size?.type && !!errors.size?.type}
                    />
                    <Form.Check.Label>{`${screen.width} x ${screen.height}`}</Form.Check.Label>
                    <Form.Text style={{ display: "block" }}>(Screen)</Form.Text>
                  </Form.Check>

                  <Form.Check
                    type="radio"
                    name="size.type"
                    label="Custom"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="custom"
                    isInvalid={touched.size?.type && !!errors.size?.type}
                  />
                  <Form.Check
                    type="radio"
                    name="size.type"
                    label="Presets"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="presets"
                    isInvalid={touched.size?.type && !!errors.size?.type}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  {values.size.type === "custom" ? (
                    <SizeCustom
                      name="size.value"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.size.value}
                      setFieldValue={setFieldValue}
                    />
                  ) : null}
                  {values.size.type === "presets" ? (
                    <SizePresets
                      type="radio"
                      name="size.value"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  ) : null}
                </Form.Group>
              </Row>

              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Getting things ready" : "Start editing"}
              </Button>
            </Form>
          )}
        </Formik>
      </Row>
    </Container>
  );
};

export default FlipbookCreate;
