import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  addLayer,
  saveAsync,
  selectSize,
  updateLayerIndex,
} from "../../../../features/flipbook/editor/flipbookEditorSlice";
import { createLayer } from "../../../core/flipbook";
import { ILayersOptionsProps } from "../Layers.types";
import Layer from "./Layer";
import LayerOptions from "./LayerOptions";

const SCALE = 0.075;

export const LayersOptions: React.FunctionComponent<ILayersOptionsProps> = ({
  layers,
}: ILayersOptionsProps) => {
  const [width, height] = useAppSelector(selectSize);
  const dispatch = useAppDispatch();
  const [addingLayer, setAddingLayer] = useState<boolean>(false);

  const _addLayer = () => {
    if (addingLayer) {
      return;
    }

    setAddingLayer(true);

    const index = !layers ? 0 : layers.length;
    dispatch(addLayer(createLayer(index)));
    dispatch(updateLayerIndex(index));
    dispatch(saveAsync());

    setAddingLayer(false);
  };

  return (
    <div>
      <LayerOptions />
      {layers?.map((layer) => (
        <Layer
          key={`layer-${layer.id}`}
          layer={layer}
          width={width * SCALE}
          height={height * SCALE}
          scale={{
            x: SCALE,
            y: SCALE,
          }}
        />
      ))}
      <div onClick={_addLayer} aria-hidden="true">
        {addingLayer ? "Disabled" : "Add Layer"}
      </div>
    </div>
  );
};
