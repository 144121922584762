import { uuidv4 } from "@firebase/util";
import { initialLayer } from "./layer";
import { Page } from "./types/page.types";

export const createPage = (index: number): Page => ({
  id: uuidv4(),
  index: index,
  layers: [initialLayer],
});

export const initialPage: Page = createPage(0);
