import React, { useRef } from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { nextIndexInViewport } from "../../../core/dom";
import { IHorizontalScrollProps } from "../HorizontalScroll.types";
import { Arrow } from "./Arrow";

const StyledHorizontalScroll = styled.div`
  display: inline-flex;
  max-width: 100%;
  overflow: hidden;
  align-items: center;
`;

const StyledHorizontalChildren = styled.div`
  display: inline-flex;
  width: 100%;
  overflow-x: hidden;
  padding-left: 0.5em;
  padding-right: 0.5em;
`;

export const HorizontalScroll: React.FunctionComponent<
  IHorizontalScrollProps
> = ({
  children,
  index = 0,
  step = 1,
  inline = "start",
  onLeftArrowClick,
  onRightArrowClick,
  ...rest
}: IHorizontalScrollProps) => {
  const [_index, _setIndex] = useState<number>(index);
  const [_maxIndex, _setMaxIndex] = useState<number>(0);
  const [_loaded, _setLoaded] = useState<boolean>(false);
  const scrollContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollContainer.current) {
      return;
    }

    _setMaxIndex(scrollContainer.current.children.length);
  }, [scrollContainer]);

  useEffect(() => {
    if (!scrollContainer.current) {
      return;
    }

    const item = scrollContainer.current.children[_index];

    if (!item) {
      return;
    }

    item.scrollIntoView({
      behavior: _loaded ? "smooth" : "auto",
      inline: inline,
    });

    if (!_loaded) {
      _setLoaded(true);
    }
  }, [_index, _loaded, inline]);

  useEffect(() => {
    if (!index) {
      return;
    }

    _setIndex(index);
  }, [index]);

  const _handleArrowClick = (direction: "left" | "right"): void => {
    const _handleLeftClick = (): void => {
      const currIndex = _index - step;

      if (currIndex <= 0) {
        _setIndex(0);
        return;
      }

      if (!scrollContainer.current) {
        _setIndex(currIndex);
        return;
      }

      const nextNotInViewIndex = nextIndexInViewport(scrollContainer.current, {
        startAt: currIndex,
        crement: "decrement",
      });

      _setIndex(nextNotInViewIndex);
    };

    const _handleRightClick = (): void => {
      const currIndex = _index + step;

      if (currIndex >= _maxIndex) {
        _setIndex(_maxIndex);
        return;
      }

      if (!scrollContainer.current) {
        _setIndex(_maxIndex);
        return;
      }

      const nextNotInViewIndex = nextIndexInViewport(scrollContainer.current, {
        startAt: currIndex,
      });

      _setIndex(nextNotInViewIndex);
    };

    if (direction === "left") {
      _handleLeftClick();

      if (onLeftArrowClick) {
        onLeftArrowClick();
      }
    } else {
      _handleRightClick();

      if (onRightArrowClick) {
        onRightArrowClick();
      }
    }
  };

  return (
    <StyledHorizontalScroll>
      <Arrow
        direction="left"
        onClick={(e) => {
          _handleArrowClick("left");
          e.stopPropagation();
          e.preventDefault();
        }}
      />
      <StyledHorizontalChildren ref={scrollContainer} {...rest}>
        {children}
      </StyledHorizontalChildren>

      <Arrow
        direction="right"
        onClick={(e) => {
          _handleArrowClick("right");
          e.stopPropagation();
          e.preventDefault();
        }}
      />
    </StyledHorizontalScroll>
  );
};

export default HorizontalScroll;
