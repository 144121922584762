import { MenuItem } from "react-pro-sidebar";
import styled from "styled-components";

export const StyledStaticMenuItem = styled(MenuItem)<{
  collapsed?: boolean;
  collapsedReducePadding?: boolean;
}>`
  a {
    cursor: auto;
    ${({ collapsed, collapsedReducePadding }) => {
      const padding = collapsed && collapsedReducePadding ? 10 : 20;
      return `padding-left: ${padding}px; padding-right: ${padding}px;`;
    }}
  }

  a:hover {
    background-color: transparent;
    color: inherit;
  }
`;
