import { FunctionComponent, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import { selectFlipbooksMetadata } from "../flipbookDashboardSlice";
import { useAppSelector } from "../../../../app/hooks";
import { SkeletonCard } from "../../../../library/controls/FlipbookCard";
import { useCurrentBreakpoint } from "../../../../library/core/size";

const XXL_COUNT = 5;
const XL_COUNT = 4;
const LG_COUNT = 3;
const MD_COUNT = 2;
const XS_COUNT = 1;

const SkeletonFlipbookCards: FunctionComponent = () => {
  const breakpoint = useCurrentBreakpoint();
  const flipbooks = useAppSelector(selectFlipbooksMetadata);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const calculateNumOfCards = (offset: number): number => {
      if (flipbooks.length === 0) {
        return 20;
      }

      if ((flipbooks.length + 1) % offset) {
        return offset - ((flipbooks.length + 1) % offset);
      } else {
        return offset;
      }
    };

    switch (breakpoint) {
      case "xxl":
        setCount(calculateNumOfCards(XXL_COUNT));
        break;
      case "xl":
        setCount(calculateNumOfCards(XL_COUNT));
        break;
      case "lg":
        setCount(calculateNumOfCards(LG_COUNT));
        break;
      case "md":
        setCount(calculateNumOfCards(MD_COUNT));
        break;
      default:
        setCount(calculateNumOfCards(XS_COUNT));
        break;
    }
  }, [breakpoint, flipbooks]);

  return (
    <>
      {Array.from(Array(count), (_, i) => {
        return (
          <Col key={`skeleton-card-${i}`}>
            <SkeletonCard />
          </Col>
        );
      })}
    </>
  );
};

export default SkeletonFlipbookCards;
