import { FunctionComponent } from "react";
import EditorPanel from "../../EditorPanel";
import FlipbookCanvas from "../../FlipbookCanvas";
import Layers from "../../Layers";
import Pages from "../../Pages";
import Toolbar from "../../Toolbar";
import { StyledEditor } from "../styles/StyledEditor";
import { EditorFlipbook } from "./EditorFlipbook";
import { EditorSidebar } from "./EditorSidebar";
import IconButton from "../../IconButton";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { BUTTON_HEIGHT, BUTTON_WIDTH } from "../../../core/styles";
import { useNavigate } from "react-router-dom";

export const Editor: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <StyledEditor>
      <EditorSidebar>
        <Toolbar />
      </EditorSidebar>
      <EditorFlipbook>
        <EditorPanel location="TOP_RIGHT">
          <Layers />
        </EditorPanel>
        <EditorPanel location="BOTTOM_LEFT">
          <Pages />
        </EditorPanel>
        <EditorPanel location="BOTTOM_RIGHT">
          <IconButton
            onClick={() => {
              navigate("/flipbooks/preview");
            }}
            iconProps={{ icon: faPlay }}
            style={{ width: BUTTON_WIDTH, height: BUTTON_HEIGHT }}
          />
        </EditorPanel>
        <FlipbookCanvas />
      </EditorFlipbook>
    </StyledEditor>
  );
};

export default Editor;
