import { uuidv4 } from "@firebase/util";
import { Layer } from "./types/layer.types";

export const createLayer = (index: number): Layer => ({
  id: uuidv4(),
  index: index,
  lines: [],
  opacity: 1,
});

export const initialLayer: Layer = createLayer(0);
