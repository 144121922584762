import { useEffect, useState } from "react";
import { breakpoint } from "./size.types";

export const useCurrentBreakpoint: () => breakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<breakpoint>(
    _calculateBreakpoint()
  );

  function _calculateBreakpoint(): breakpoint {
    // XX-Large devices (larger desktops, 1400px and up)
    if (window.matchMedia("(min-width: 1400px)").matches) {
      return "xxl";
    }

    // X-Large devices (large desktops, 1200px and up)
    if (window.matchMedia("(min-width: 1200px)").matches) {
      return "xl";
    }

    // Large devices (desktops, 992px and up)
    if (window.matchMedia("(min-width: 992px)").matches) {
      return "lg";
    }

    // Medium devices (tablets, 768px and up)
    if (window.matchMedia("(min-width: 768px)").matches) {
      return "md";
    }

    // Small devices (landscape phones, 576px and up)
    if (window.matchMedia("(min-width: 576px)").matches) {
      return "sm";
    }

    // X-Small devices (portrait phones, less than 576px)
    // No media query for `xs` since this is the default in Bootstrap
    return "xs";
  }

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      setBreakpoint(_calculateBreakpoint());
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return breakpoint;
};

export default useCurrentBreakpoint;
