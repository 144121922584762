import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../../..";
import { FlipbookDetailedUser } from "./auth.types";

export const registerWithEmailAndPassword = async (
  name: string,
  email: string,
  password: string
) => {
  const res = await createUserWithEmailAndPassword(auth, email, password);
  const user = res.user;

  const newUser: FlipbookDetailedUser = {
    uid: user.uid,
    name,
    authProvider: "local",
    email,
  };

  await setDoc(doc(db, "users", user.uid), newUser);
};

export const logInWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  await signInWithEmailAndPassword(auth, email, password);
};

export const sendPasswordReset = async (email: string) => {
  await sendPasswordResetEmail(auth, email);
  alert("Password reset link sent!");
};

export const logout = () => {
  signOut(auth);
};
