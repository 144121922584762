import { useAppSelector } from "../../../../app/hooks";
import { selectDrawingState } from "../../../../features/flipbook/editor/flipbookEditorSlice";
import { IEditorPanelProps } from "../EditorPanel.types";
import { StyledEditorPanel } from "../styles/StyledEditorPanel";

export const EditorPanel: React.FunctionComponent<IEditorPanelProps> = ({
  children,
  ...rest
}: IEditorPanelProps) => {
  const { isDrawing } = useAppSelector(selectDrawingState);

  return (
    <StyledEditorPanel {...rest} isDrawing={isDrawing}>
      {children}
    </StyledEditorPanel>
  );
};

export default EditorPanel;
