import { FunctionComponent, useCallback, useRef, useState } from "react";
import IconButton from "../../../../library/controls/IconButton";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import useOnClickOutside from "../../../../library/core/click";
import { useAppSelector } from "../../../../app/hooks";
import {
  selectFlipbook,
  selectPreview,
} from "../../editor/flipbookEditorSlice";
import styled from "styled-components";
import { PADDING } from "../../../../library/core/styles";

const DownloadOptions = styled.div`
  position: absolute;
  bottom: 50px;
  right: 0;
  width: 116px;
  background-color: var(--bs-secondary);
  color: white;
  padding: ${PADDING};
  z-index: 100;
  opacity: 0.95;

  a {
    color: white;
    text-decoration: none;
  }
`;

export const Download: FunctionComponent = () => {
  const [isOpen, setIsOpen] = useState<boolean>();
  const ref = useRef<HTMLDivElement>(null);
  const preview = useAppSelector(selectPreview);
  const flipbook = useAppSelector(selectFlipbook);

  useOnClickOutside(
    ref,
    useCallback(() => {
      setIsOpen(false);
    }, [setIsOpen])
  );

  return (
    <div ref={ref}>
      <IconButton
        iconProps={{ icon: faDownload }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      {isOpen ? (
        <DownloadOptions>
          {preview.type === "PREVIEW" ? (
            <a href={preview.data} download={flipbook?.name}>
              Download Gif
            </a>
          ) : null}
        </DownloadOptions>
      ) : null}
    </div>
  );
};
