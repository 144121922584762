import { IFlipbookApi } from "./FlipbookApi.types";
import Flipbook from "./areas/flipbook";
import Flipbooks from "./areas/flipbooks";

export class FlipbookApi implements IFlipbookApi {
  private _uid: string;

  public flipbooks: Flipbooks;
  public flipbook: Flipbook;

  constructor(uid: string) {
    this._uid = uid;

    this.flipbooks = new Flipbooks({ uid });
    this.flipbook = new Flipbook({ uid });
  }
}

export default FlipbookApi;
