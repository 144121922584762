/* eslint-disable react/display-name */
import { forwardRef } from "react";
import { Stage } from "react-konva";
import { Stage as StageApi } from "konva/lib/Stage";
import { ICanvasProps } from "../Canvas.types";
import CanvasLayer from "./CanvasLayer";

export const Canvas = forwardRef<StageApi, ICanvasProps>(function (
  { layers, selectedLayerIndex, line, ...rest },
  ref
) {
  return (
    <Stage {...rest} ref={ref}>
      {layers.map((layer) => (
        <CanvasLayer
          key={layer.id}
          layer={layer}
          line={selectedLayerIndex === layer.index ? line : undefined}
        />
      ))}
    </Stage>
  );
});

export default Canvas;
