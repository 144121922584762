import { IEditorPanelItemOptionsProps } from "../EditorPanel.types";
import { StyledEditorPanelItemOptions } from "../styles/StyledEditorPanelItemOptions";

export const EditorPanelItemOptions: React.FunctionComponent<
  IEditorPanelItemOptionsProps
> = ({ children, ...rest }: IEditorPanelItemOptionsProps) => {
  return (
    <StyledEditorPanelItemOptions {...rest}>
      {children}
    </StyledEditorPanelItemOptions>
  );
};

export default EditorPanelItemOptions;
