import { FunctionComponent } from "react";
import { useFlipbookUser } from "../../library/core/flipbook";
import { default as MenuItem } from "./MenuItem";

const ProfileIcon: FunctionComponent = () => {
  const [user] = useFlipbookUser();

  return (
    <MenuItem
      icon={
        <img
          src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
          alt=""
          height={35}
          style={{ borderRadius: 50 }}
        />
      }
      to={"/user"}
    >
      {user?.name}
    </MenuItem>
  );
};

export default ProfileIcon;
