import React from "react";
import { IEditorPanelInfoProps } from "../EditorPanel.types";
import { StyledEditorPanelItem } from "../styles/StyledEditorPanelItem";

export const EditorPanelInfo: React.FunctionComponent<
  IEditorPanelInfoProps
> = ({ children, className, ...rest }: IEditorPanelInfoProps) => {
  return (
    <StyledEditorPanelItem
      className={className ? `${className} info` : "info"}
      {...rest}
    >
      {children}
    </StyledEditorPanelItem>
  );
};

export default EditorPanelInfo;
