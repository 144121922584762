import { StyledToolbarItemOptions } from "../styles/StyledToolbarItemOptions";
import { IToolbarItemOptionsProps } from "../Toolbar.types";

export const ToolbarItemOptions: React.FunctionComponent<
  IToolbarItemOptionsProps
> = ({ children, ...rest }: IToolbarItemOptionsProps) => {
  return (
    <StyledToolbarItemOptions {...rest}>{children}</StyledToolbarItemOptions>
  );
};

export default ToolbarItemOptions;
