import { FunctionComponent } from "react";
import {
  ColourBackground,
  FlipbookPreview,
  ImageBackground,
} from "../../../core/flipbook";
import { Card } from "react-bootstrap";

export interface FlipbookCardImageProps {
  preview: ColourBackground | ImageBackground | FlipbookPreview;
}

export const FlipbookCardImage: FunctionComponent<FlipbookCardImageProps> = ({
  preview,
}) => {
  if (preview.type === "IMAGE") {
    return <Card.Img style={{ height: 150 }} src={preview.src} />;
  }

  if (preview.type === "PREVIEW") {
    return <Card.Img style={{ height: 150 }} src={preview.data} />;
  }

  return (
    <Card.Img
      as="div"
      style={{ backgroundColor: preview.colour, height: 150 }}
    />
  );
};
