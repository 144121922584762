import React, { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { registerWithEmailAndPassword } from "../../../library/apis/auth";
import { SchemaOf, object, ref, string } from "yup";
import { Formik, FormikHelpers } from "formik";
import {
  Alert,
  Button,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";

interface FormValues {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

const RegisterSchema: SchemaOf<FormValues> = object({
  name: string().required("Required"),
  email: string()
    .required("Required")
    .email("Please enter a valid email address"),
  password: string().required("Required").matches(passwordRules, {
    message:
      "Your password must be at least 8 characters long, contain at least one number and have a mixture of uppercase and lowercase letters.",
  }),
  passwordConfirmation: string()
    .required("Passwords must match")
    .oneOf([ref("password"), null], "Passwords must match"),
});

export const Register: React.FunctionComponent = () => {
  const [formError, setFormError] = useState<ReactNode>(null);

  const handleSubmit = async (
    { name, email, password }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setFormError(null);
      await registerWithEmailAndPassword(name, email, password);
    } catch (e) {
      setFormError(
        <div>
          An error occurred trying to register... Please try again later or
          contact support.
        </div>
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <Row>
        <h1>Register</h1>

        {formError && <Alert variant="danger">{formError}</Alert>}

        <Formik<FormValues>
          initialValues={{
            name: "",
            email: "",
            password: "",
            passwordConfirmation: "",
          }}
          validationSchema={RegisterSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <Form.Group className="mb-3">
                <FloatingLabel label="Name">
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    size="sm"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    isInvalid={touched.name && !!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3">
                <FloatingLabel label="Email">
                  <Form.Control
                    type="email"
                    placeholder="E-mail Address"
                    size="sm"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isInvalid={touched.email && !!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3">
                <FloatingLabel label="Password">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    size="sm"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3">
                <FloatingLabel label="Confirm password">
                  <Form.Control
                    type="password"
                    placeholder="Confirm password"
                    size="sm"
                    name="passwordConfirmation"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.passwordConfirmation}
                    isInvalid={
                      touched.passwordConfirmation &&
                      !!errors.passwordConfirmation
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.passwordConfirmation}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Creating your account" : "Register"}
              </Button>

              <div>
                Already have an account? <Link to="/Login">Login</Link> now.
              </div>
            </Form>
          )}
        </Formik>
      </Row>
    </Container>
  );
};

export default Register;
