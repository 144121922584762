import React, { ForwardedRef } from "react";
import IconButton from "../../IconButton";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { DropdownToggleProps } from "react-bootstrap/esm/DropdownToggle";

const FlipbookOptionsToggleComponent: React.FunctionComponent<
  DropdownToggleProps & { ref: ForwardedRef<DropdownToggleProps> }
> = ({ onClick, ref }) => {
  return (
    <IconButton
      ref={ref as any}
      variant="light"
      iconProps={{ icon: faEllipsisV }}
      onClick={onClick}
    />
  );
};

export const FlipbookOptionsToggle = React.forwardRef<DropdownToggleProps>(
  function FlipbookOptionsToggle(props, ref) {
    return <FlipbookOptionsToggleComponent {...props} ref={ref} />;
  }
);

export default FlipbookOptionsToggle;
