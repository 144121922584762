import { createSlice } from "@reduxjs/toolkit";

export interface FlipbookCreationState {}

const initialState: FlipbookCreationState = {
  flipbook: undefined,
};

export const flipbookCreationSlice = createSlice({
  name: "flipbookCreation",
  initialState,
  reducers: {},
});

/** SELECTORS */
export default flipbookCreationSlice.reducer;
