import { FunctionComponent } from "react";
import { Line as KonvaLine, Layer } from "react-konva";
import { Line } from "../../../core/flipbook";
import { ICanvasLayerProps } from "../Canvas.types";

// TODO: Add support for additional tool

export const CanvasLayer: FunctionComponent<ICanvasLayerProps> = ({
  layer,
  line,
}: ICanvasLayerProps) => {
  if (!layer) {
    return null;
  }

  return (
    <Layer opacity={layer.opacity}>
      {layer.lines.map((line: Line, i: number) => (
        <KonvaLine
          key={i}
          points={line.points}
          stroke={line.tool.stroke}
          strokeWidth={line.tool.strokeWidth}
          tension={0.5}
          lineCap="round"
          lineJoin="round"
          globalCompositeOperation={
            line.tool.type === "Eraser" ? "destination-out" : "source-over"
          }
          offsetY={layer.index}
        />
      ))}
      {line && (
        <KonvaLine
          points={line.points}
          stroke={line.tool.stroke}
          strokeWidth={line.tool.strokeWidth}
          tension={0.5}
          lineCap="round"
          lineJoin="round"
          globalCompositeOperation={
            line.tool.type === "Eraser" ? "destination-out" : "source-over"
          }
          offsetY={layer.index}
        />
      )}
    </Layer>
  );
};

export default CanvasLayer;
