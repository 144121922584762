import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";
import Editor from "../../../../library/controls/Editor";
import { useLockBodyScroll } from "../../../../library/core/scroll";
import { useFlipbookEditor } from "../hooks/useFlipbookEditor";

const FlipbookEditor: FunctionComponent = () => {
  useLockBodyScroll();
  const { loading, selected, flipbook } = useFlipbookEditor();

  if (!selected) {
    return (
      <Navigate
        to="/flipbooks"
        state={{
          toast: {
            header: "Error",
            body: "An error occurred opening your flipbook",
          },
        }}
      />
    );
  }

  if (loading) {
    return <div>Loading</div>;
  }

  if (!flipbook) {
    return (
      <Navigate
        to="/flipbooks"
        state={{
          toast: {
            header: "Error",
            body: "An error occurred opening your flipbook",
          },
        }}
      />
    );
  }

  return <Editor />;
};

export default FlipbookEditor;
