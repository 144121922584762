import { FC } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../..";
import { IAuthorisedProps } from "../Authorised.types";

export const Authorised: FC<IAuthorisedProps> = ({
  children,
  isUnauthorised,
  unauthorised = null,
}: IAuthorisedProps) => {
  const [user] = useAuthState(auth);

  if (!isUnauthorised) {
    if (!user) {
      return unauthorised ? <>{unauthorised}</> : null;
    }

    return <>{children}</>;
  }

  if (user) {
    return unauthorised ? <>{unauthorised}</> : null;
  }

  return <>{children}</>;
};

export default Authorised;
