import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../..";
import { FlipbookUser } from "../../apis/auth";

export const useFlipbookUser: () => [
  FlipbookUser | undefined,
  boolean,
  Error | undefined
] = () => {
  const [userDetails, setUserDetails] = useState<FlipbookUser | undefined>(
    undefined
  );

  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const q = query(collection(db, "users"), where("uid", "==", user?.uid));
        const doc = await getDocs(q);
        const data = doc.docs[0].data();

        setUserDetails({
          uid: data.uid,
          name: data.name,
          email: data.email,
        });
      } catch (e) {
        console.error(e);
      }
    };

    if (loading || !user) {
      return;
    }

    fetchUserName();
  }, [user, loading]);

  return [userDetails, loading, error];
};

export default useFlipbookUser;
