import FlipbookApi from "./flipbookApi";

export let flipbookApi: FlipbookApi;

export function setFlipbookApi(api: FlipbookApi) {
  flipbookApi = api;
}

export function resetFlipbookApi() {
  flipbookApi = undefined as unknown as FlipbookApi;
}
