import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { flipbookApi } from "../../../library/apis";
import { FlipbookMetadata } from "../../../library/core/flipbook";

export interface FlipbookDashboardState {
  flipbooksMetadata: FlipbookMetadata[];
  status: "idle" | "loading" | "loaded" | "failed";
}

export const fetchFlipbooksAsync = createAsyncThunk<
  FlipbookMetadata[],
  {
    limit?: number | undefined;
  }
>(
  "flipbookDashboard/fetchFlipbooks",
  async ({ limit }): Promise<FlipbookMetadata[]> => {
    const response = await flipbookApi.flipbooks.fetch(limit);
    return response;
  }
);

export const fetchMoreFlipbooksAsync = createAsyncThunk<
  FlipbookMetadata[],
  {
    uid: string;
    limit?: number | undefined;
  },
  { state: RootState }
>(
  "flipbookDashboard/fetchMoreFlipbooks",
  async ({ limit }, { getState }): Promise<FlipbookMetadata[]> => {
    const flipbooks = getState().flipbookDashboard.flipbooksMetadata;

    if (flipbooks.length === 0) {
      return [];
    }

    const lastVisited = flipbooks[flipbooks.length - 1].created;

    const response = await flipbookApi.flipbooks.fetch(limit, {
      created: lastVisited,
    });

    return response;
  }
);

export const deleteFlipbookAsync = createAsyncThunk<
  string,
  {
    id: string;
  }
>("flipbookDashboard/deleteFlipbook", async ({ id }): Promise<string> => {
  await flipbookApi.flipbook.delete(id);

  return id;
});

const initialState: FlipbookDashboardState = {
  flipbooksMetadata: [],
  status: "idle",
};

export const flipbookDashboardSlice = createSlice({
  name: "flipbookDashboard",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<FlipbookMetadata>) => {
      state.flipbooksMetadata.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    // Fetch
    builder
      .addCase(fetchFlipbooksAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFlipbooksAsync.fulfilled, (state, action) => {
        state.status = "loaded";
        state.flipbooksMetadata = action.payload;
      })
      .addCase(fetchFlipbooksAsync.rejected, (state) => {
        state.status = "failed";
      });

    // Fetch more
    builder
      .addCase(fetchMoreFlipbooksAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMoreFlipbooksAsync.fulfilled, (state, action) => {
        state.status = "loaded";
        state.flipbooksMetadata = [
          ...state.flipbooksMetadata,
          ...action.payload,
        ];
      })
      .addCase(fetchMoreFlipbooksAsync.rejected, (state) => {
        state.status = "failed";
      });

    // Delete
    builder.addCase(deleteFlipbookAsync.fulfilled, (state, action) => {
      state.flipbooksMetadata = state.flipbooksMetadata.filter(
        (x) => x.id !== action.payload
      );
    });
  },
});

export const { add } = flipbookDashboardSlice.actions;

/** SELECTORS */
export const selectFlipbooksMetadata = (state: RootState) =>
  state.flipbookDashboard.flipbooksMetadata;

export default flipbookDashboardSlice.reducer;
