import { FunctionComponent, useState } from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";

const Error: FunctionComponent = () => {
  const [showToast, setShowToast] = useState(true);
  const location = useLocation();

  if (!location.state?.toast) {
    return null;
  }

  return (
    <ToastContainer className="p-3" position="top-end">
      <Toast onClose={() => setShowToast(false)} show={showToast} autohide>
        <Toast.Header>
          <FontAwesomeIcon icon={faSquare} />
          <strong className="me-auto ps-1">
            {location.state.toast.header}
          </strong>
          <small>just now</small>
        </Toast.Header>
        <Toast.Body>{location.state.toast.body}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default Error;
