import React from "react";
import useOnClickOutside from "../../../core/click";
import IconButton from "../../IconButton/components/IconButton";
import { StyledToolbarItem } from "../styles/StyledToolbarItem";
import { IToolbarItemProps } from "../Toolbar.types";
import ToolbarItemOptions from "./ToolbarItemOptions";

// TODO: A WAY OF LETTING THE USER KNOW THERE IS OPTIONS MENU

export const ToolbarItem: React.FunctionComponent<IToolbarItemProps> = ({
  selected,
  children,
  onClick,
  ...rest
}: IToolbarItemProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState<boolean>();
  useOnClickOutside(
    ref,
    React.useCallback(() => setIsOpen(false), [setIsOpen])
  );

  return (
    <StyledToolbarItem ref={ref}>
      <IconButton
        {...rest}
        active={selected}
        onClick={(e) => {
          if (onClick) {
            onClick(e);
          }

          if (!selected) {
            return;
          }

          setIsOpen(!isOpen);
        }}
      />
      {isOpen && children ? (
        <ToolbarItemOptions>{children}</ToolbarItemOptions>
      ) : null}
    </StyledToolbarItem>
  );
};

export default ToolbarItem;
