import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faBrush,
  faChevronLeft,
  faChevronRight,
  faCircleInfo,
  faEye,
  faHome,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import { Sidebar, useProSidebar } from "react-pro-sidebar";
import { Outlet } from "react-router-dom";
import Authorised from "../../library/controls/Authorised/components/Authorised";
import { logout } from "../../library/apis/auth";
import MenuItem from "./MenuItem";
import ProfileIcon from "./ProfileIcon";
import SidebarContainer from "./SidebarContainer";
import StaticMenuItem from "./StaticMenuItem";
import { StyledLayout, StyledMain, StyledMenu } from "./styles/StyledLayout";
import SubMenu from "./SubMenu";

function Layout() {
  const { collapseSidebar, collapsed } = useProSidebar();

  return (
    <StyledLayout>
      <Sidebar>
        <StyledMenu closeOnClick>
          <div>
            <StaticMenuItem icon={<Icon icon={faBrush} />}>
              Flipbook
            </StaticMenuItem>

            <SidebarContainer>
              <Authorised>
                <ProfileIcon />
              </Authorised>
            </SidebarContainer>
            <SidebarContainer>
              <MenuItem icon={<Icon icon={faHome} />} to={"/"} end>
                Home
              </MenuItem>
              <MenuItem icon={<Icon icon={faEye} />} to="/showcases">
                Showcases
              </MenuItem>
            </SidebarContainer>

            <Authorised isUnauthorised={true}>
              <SidebarContainer>
                <StaticMenuItem collapsedReducePadding>
                  <span className="text-secondary">Design</span>
                </StaticMenuItem>
                <MenuItem
                  icon={<Icon icon={faArrowRightToBracket} />}
                  to="/login"
                >
                  Login
                </MenuItem>

                <MenuItem
                  icon={<Icon icon={faArrowRightToBracket} />}
                  to="/register"
                >
                  Register
                </MenuItem>
              </SidebarContainer>
            </Authorised>

            <Authorised>
              <SidebarContainer>
                <StaticMenuItem collapsedReducePadding>
                  <span className="text-secondary">Design</span>
                </StaticMenuItem>
                <SubMenu
                  icon={<Icon icon={faBrush} />}
                  label="Flipbooks"
                  to="/flipbooks"
                  end={collapsed ? false : true}
                >
                  <MenuItem
                    icon={<Icon icon={faPlus} />}
                    to="/flipbooks/create"
                  >
                    Create
                  </MenuItem>
                </SubMenu>
                {/* TODO: MOVE LOGOUT TO PROFILE PICTURE */}
                <MenuItem
                  icon={<Icon icon={faArrowRightFromBracket} />}
                  to="/logout"
                  onClick={logout}
                >
                  Logout
                </MenuItem>
              </SidebarContainer>
            </Authorised>

            <SidebarContainer>
              <StaticMenuItem collapsedReducePadding>
                <span className="text-secondary">Support</span>
              </StaticMenuItem>
              <MenuItem icon={<Icon icon={faCircleInfo} />} to="/help">
                Help
              </MenuItem>
              <MenuItem icon={<Icon icon={faCommentDots} />} to="/feedback">
                Submit feedback
              </MenuItem>
            </SidebarContainer>
          </div>

          <div>
            <MenuItem
              onClick={() => collapseSidebar()}
              icon={<Icon icon={collapsed ? faChevronRight : faChevronLeft} />}
            ></MenuItem>
          </div>
        </StyledMenu>
      </Sidebar>
      <StyledMain id="flipbook-main">
        <Outlet />
      </StyledMain>
    </StyledLayout>
  );
}

export default Layout;
