import styled from "styled-components";
import { BUTTON_HEIGHT } from "../../../core/styles";

export const StyledToolbarItem = styled.div`
  height: ${BUTTON_HEIGHT};

  & button {
    border: none;
    width: 100%;
    height: 100%;
  }
`;
