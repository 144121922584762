import { FunctionComponent } from "react";
import { useAppSelector } from "../../../../app/hooks";
import Page from "./Page";
import { IPagesProps } from "../Pages.types";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { AddPage } from "./AddPage";
import EditorPanelItem from "../../EditorPanel/components/EditorPanelItem";
import EditorPanelInfo from "../../EditorPanel/components/EditorPanelInfo";
import HorizontalScroll from "../../HorizontalScroll/components/HorizontalScroll";
import styled from "styled-components";
import {
  selectPageIndex,
  selectPages,
} from "../../../../features/flipbook/editor/flipbookEditorSlice";

const StyledHorizontalScroll = styled(HorizontalScroll)`
  gap: 0.5em;
  align-items: center;
`;

export const Pages: FunctionComponent<IPagesProps> = () => {
  const pages = useAppSelector(selectPages);
  const selectedPage = useAppSelector(selectPageIndex);

  return (
    <>
      <EditorPanelItem iconProps={{ icon: faFile }} location={"BOTTOM_LEFT"}>
        <StyledHorizontalScroll index={selectedPage} step={5} inline="center">
          {pages?.map((x, i) => (
            <Page key={`page-${x.id}-${i}`} index={x.index} layers={x.layers} />
          ))}
          <AddPage />
        </StyledHorizontalScroll>
      </EditorPanelItem>
      <EditorPanelInfo>
        Page {selectedPage + 1}/{pages?.length}
      </EditorPanelInfo>
    </>
  );
};

export default Pages;
