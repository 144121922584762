import styled from "styled-components";
import { PADDING } from "../../../../core/styles";

export const StyledPageOptions = styled.div`
  position: absolute;
  bottom: 100%;
  background-color: var(--bs-secondary);
  color: white;
  padding: ${PADDING};
  z-index: 100;
  opacity: 0.95;
`;
