import { FunctionComponent } from "react";
import styled from "styled-components";

const Skeleton = styled.div`
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);

  min-height: 280px;
  height: 100%;
  width: 100%;
  background: #fff;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
`;

const SkeletonSquare = styled.div`
  min-height: 150px;
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: wave-squares 2s infinite ease-out;

  @keyframes wave-squares {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
`;

const SkeletonLine = styled.div<{
  width?: string;
  height?: string;
  margin?: string;
}>`
  ${({ width = "auto" }) => {
    return `width: ${width};`;
  }}
  ${({ height = "20px" }) => {
    return `height: ${height};`;
  }}
  ${({ margin = "1em" }) => {
    return `margin: ${margin};`;
  }}
  
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;

  @keyframes wave-lines {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
`;

export const SkeletonCard: FunctionComponent = () => {
  return (
    <Skeleton>
      <SkeletonSquare />
      <SkeletonLine width="50%" />
      <SkeletonLine height="10px" />
      <SkeletonLine height="25px" width="50px" />
    </Skeleton>
  );
};

export default SkeletonCard;
