export function isElementInViewport(el: Element) {
  var rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export interface INextIndexInViewportOptions {
  /** The index to start checking at.
   * @default if `crement === "increment"` then `0` otherwise if `crement === "decrement"` then `parent.children.length`
   */
  startAt?: number;

  /** Whether to increment or decrement */
  crement?: "increment" | "decrement";
}

/**
 * Returns the index of the child who is not visible within the viewport or last index if they are all visible.
 */
export function nextIndexInViewport(
  parent: Element,
  options?: INextIndexInViewportOptions
): number {
  const {
    crement = "increment",
    startAt = options?.crement === "decrement" ? parent.children.length : 0,
  } = options ? options : {};

  const child = parent.children[startAt];

  if (!child) {
    return crement === "increment" ? parent.children.length : 0;
  }

  const isNextIndexInViewport = isElementInViewport(child);

  if (!isNextIndexInViewport) {
    return startAt;
  }

  return nextIndexInViewport(parent, {
    crement: crement,
    startAt: crement === "increment" ? startAt + 1 : startAt - 1,
  });
}
