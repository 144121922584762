import { FunctionComponent } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useLocation } from "react-router-dom";
import { auth } from "../../../..";
import { IRequireUnAuthOrRedirectProps } from "../RequireUnAuthOrRedirect.types";

export const RequireUnAuthOrRedirect: FunctionComponent<
  IRequireUnAuthOrRedirectProps
> = ({ children }) => {
  const [user, loading] = useAuthState(auth);
  let location = useLocation();

  if (loading) {
    return null;
  }

  if (user) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return <>{children}</>;
};

export default RequireUnAuthOrRedirect;
