import Container from "react-bootstrap/Container";
import FlipbookCards from "./FlipbookCards";
import {
  fetchFlipbooksAsync,
  fetchMoreFlipbooksAsync,
  selectFlipbooksMetadata,
} from "../flipbookDashboardSlice";
import { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import Error from "./Error";
import { auth } from "../../../..";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import LastEditedFlipbook from "../../../../library/controls/LastEditedFlipbook";
import { useInfiniteScroll } from "../../../../library/core/scroll";

function FlipbookDashboard() {
  const dispatch = useAppDispatch();
  const [user] = useAuthState(auth);
  const loader = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const flipbooks = useAppSelector(selectFlipbooksMetadata);

  const isFetching = useInfiniteScroll(
    loader,
    async () => {
      if (!user) {
        return;
      }

      // Add Fake Delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      await dispatch(
        fetchMoreFlipbooksAsync({
          uid: user.uid,
        })
      );
    },
    {
      disabled: !loaded || flipbooks.length === 0,
    }
  );

  useEffect(() => {
    async function initialFlipbooks() {
      if (!user) {
        return;
      }

      await dispatch(fetchFlipbooksAsync({}));

      setLoaded(true);
    }

    initialFlipbooks();
  }, [dispatch, user]);

  if (!user) {
    return null;
  }

  return (
    <Container>
      <Error />
      <h1>Flipbooks</h1>
      <LastEditedFlipbook />
      <FlipbookCards isFetching={isFetching} isLoading={!loaded} />
      <div className="pt-4" ref={loader} />
    </Container>
  );
}

export default FlipbookDashboard;
