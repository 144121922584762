import { ReactNode, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sendPasswordReset } from "../../../library/apis/auth";
import { SchemaOf, object, string } from "yup";
import { Formik, FormikHelpers } from "formik";
import {
  Alert,
  Button,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";

interface FormValues {
  email: string;
}

const ResetSchema: SchemaOf<FormValues> = object({
  email: string()
    .required("Required")
    .email("Please enter a valid email address"),
});

export const Reset: React.FunctionComponent = () => {
  const [formError, setFormError] = useState<ReactNode>(null);
  const navigate = useNavigate();

  const handleSubmit = async (
    { email }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setFormError(null);
      await sendPasswordReset(email);

      navigate("/reset-confirmation");
    } catch (e) {
      setFormError(
        <div>
          An error occurred trying to send reset email... Please try again later
          or contact support.
        </div>
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <Row>
        <h1>Reset your password</h1>

        {formError && <Alert variant="danger">{formError}</Alert>}

        <Formik<FormValues>
          initialValues={{ email: "" }}
          validationSchema={ResetSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <Form.Group className="mb-3">
                <FloatingLabel label="Email">
                  <Form.Control
                    type="email"
                    placeholder="E-mail Address"
                    size="sm"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isInvalid={touched.email && !!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Sending" : "Send password reset email"}
              </Button>

              <div>
                Don&apos;t have an account? <Link to="/register">Register</Link>{" "}
                now.
              </div>
            </Form>
          )}
        </Formik>
      </Row>
    </Container>
  );
};

export default Reset;
