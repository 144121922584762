/* eslint-disable react/display-name */
import { KonvaEventObject } from "konva/lib/Node";
import { forwardRef, useEffect, useState } from "react";
import { IDrawingState } from "../../../../features/flipbook/editor/flipbookEditorSlice";
import { Line } from "../../../core/flipbook";
import Canvas from "../../Canvas";
import { IDrawableCanvasProps } from "../DrawableCanvas.types";
import { Stage } from "konva/lib/Stage";

export const DrawableCanvas = forwardRef<Stage, IDrawableCanvasProps>(function (
  { tool, onDrawing, onDraw, ...rest }: IDrawableCanvasProps,
  ref
) {
  const [line, setLine] = useState<Line>();
  const [drawingState, setDrawingState] = useState<IDrawingState>({
    isDrawing: false,
  });

  useEffect(
    /**
     * Effect to call the `onDrawing` property when `isDrawing` toggles.
     */
    () => {
      if (!onDrawing) {
        return;
      }

      onDrawing(drawingState);
    },
    [onDrawing, drawingState]
  );

  const handleMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    const pos = e?.target?.getStage()?.getPointerPosition();
    setDrawingState({
      isDrawing: true,
    });
    setLine({ tool: tool, points: pos ? [pos.x, pos.y] : [] });
  };

  const handleMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    // No drawing - skipping
    if (!drawingState.isDrawing) {
      return;
    }

    if (!line) {
      return;
    }

    const stage = e.target.getStage();

    if (!stage) {
      return;
    }

    const point = stage.getPointerPosition();

    if (!point) {
      return;
    }

    setLine({ ...line, points: line.points.concat([point.x, point.y]) });
  };

  const handleMouseUp = () => {
    if (!line) {
      setLine(undefined);
      setDrawingState({
        isDrawing: false,
      });
      return;
    }

    onDraw(line);

    setLine(undefined);
    setDrawingState({
      isDrawing: false,
    });
  };

  return (
    <Canvas
      {...rest}
      ref={ref}
      onMouseDown={handleMouseDown}
      onMousemove={handleMouseMove}
      onMouseup={handleMouseUp}
      onTouchStart={handleMouseDown}
      onTouchMove={handleMouseMove}
      onTouchEnd={handleMouseUp}
      line={line}
    />
  );
});

export default DrawableCanvas;
