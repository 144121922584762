import { Menu } from "react-pro-sidebar";
import styled from "styled-components";

export const StyledLayout = styled.div`
  display: flex;
  height: 100%;

  ul {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
`;

export const StyledMain = styled.main`
  padding: 1em;
  width: 100%;
  overflow-y: auto;
`;

export const StyledMenu = styled(Menu)`
  height: 100%;
`;
