import { formatDistanceToNow } from "date-fns";
import { FunctionComponent } from "react";
import Card from "react-bootstrap/Card";
import { useProSidebar } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../app/hooks";
import { select } from "../../../../features/flipbook/selection/flipbookSelectionSlice";
import { IFlipbookCardProps } from "../FlipbookCard.types";
import { Dropdown } from "react-bootstrap";
import FlipbookOptionsToggle from "./FlipbookOptionsToggle";
import { deleteFlipbookAsync } from "../../../../features/flipbook/dashboard/flipbookDashboardSlice";
import { faPencil, faPlay, faTrash } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { FlipbookCardImage } from "./FlipbookCardImage";

export const FlipbookCard: FunctionComponent<IFlipbookCardProps> = ({
  id,
  name,
  edited,
  preview,
}: IFlipbookCardProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { collapseSidebar } = useProSidebar();

  const handleEdit = () => {
    dispatch(select(id));
    navigate("/flipbooks/editor");
    collapseSidebar(true);
  };

  const handlePreview = () => {
    dispatch(select(id));
    navigate("/flipbooks/preview");
    collapseSidebar(true);
  };

  const handleDeleteUnconfirmed = () => {
    if (window.confirm(`Are you sure you want to delete flipbook '${name}'.`)) {
      dispatch(deleteFlipbookAsync({ id }));
    }
  };

  return (
    <Card>
      <FlipbookCardImage preview={preview} />

      <Card.Body style={{ borderTop: "solid var(--bs-light) 1px" }}>
        <Card.Title>{name}</Card.Title>
        <Card.Text>
          <small>
            <i>Edited: {formatDistanceToNow(new Date(edited))} ago</i>
          </small>
        </Card.Text>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", gap: "0.5em" }}>
            <IconButton
              iconProps={{ icon: faPencil }}
              title="Edit"
              variant="primary"
              onClick={handleEdit}
            >
              Edit
            </IconButton>
            <IconButton
              iconProps={{ icon: faPlay }}
              title="Play"
              variant="primary"
              onClick={handlePreview}
            />
          </div>

          <Dropdown drop="up">
            <Dropdown.Toggle as={FlipbookOptionsToggle} />
            <Dropdown.Menu>
              <Dropdown.Item
                eventKey="1"
                title="Edit options"
                onClick={() => {
                  alert("Edit");
                }}
              >
                <FontAwesomeIcon icon={faEdit} /> Edit Options
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                title="Delete"
                onClick={handleDeleteUnconfirmed}
              >
                <FontAwesomeIcon icon={faTrash} /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Card.Body>
    </Card>
  );
};

export default FlipbookCard;
