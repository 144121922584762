import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { FunctionComponent } from "react";
import { useAppSelector } from "../../../../app/hooks";
import {
  selectFlipbook,
  selectLayerIndex,
  selectLayers,
  selectPage,
} from "../../../../features/flipbook/editor/flipbookEditorSlice";
import EditorPanelInfo from "../../EditorPanel/components/EditorPanelInfo";
import EditorPanelItem from "../../EditorPanel/components/EditorPanelItem";
import { LayersOptions } from "./LayersOptions";

export const Layers: FunctionComponent = () => {
  const flipbook = useAppSelector(selectFlipbook);
  const page = useAppSelector(selectPage);
  const layers = useAppSelector(selectLayers);
  const selectedLayer = useAppSelector(selectLayerIndex);

  if (!flipbook || !page) {
    return null;
  }

  return (
    <>
      <EditorPanelItem iconProps={{ icon: faLayerGroup }} location="TOP_RIGHT">
        <LayersOptions layers={layers} />
      </EditorPanelItem>
      <EditorPanelInfo className="vertical">
        Layer {selectedLayer + 1}/{layers?.length}
      </EditorPanelInfo>
    </>
  );
};

export default Layers;
