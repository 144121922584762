import styled from "styled-components";
import { PADDING, SIDEBAR_WIDTH } from "../../../core/styles";

export const StyledToolbarItemOptions = styled.div`
  position: absolute;
  left: ${SIDEBAR_WIDTH};
  background-color: red;
  padding: ${PADDING};
  z-index: 100;
`;
