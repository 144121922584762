import { PageOptionsItemProps } from "../Pages.types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const StyledPageOptionsItem = styled.div`
  padding: 0.5em;

  &:hover[aria-disabled="false"],
  &:active[aria-disabled="false"],
  &:focus[aria-disabled="false"] {
    background-color: var(--bs-gray-dark);
    cursor: pointer;
  }

  &[aria-disabled="true"] {
    color: var(--bs-gray-500);
  }
`;

export const PageOptionsItem: React.FunctionComponent<PageOptionsItemProps> = ({
  icon,
  label,
  onClick,
  disabled,
  ...rest
}: PageOptionsItemProps) => {
  const [_disabled, _setDisabled] = React.useState<boolean>(false);

  return (
    <StyledPageOptionsItem
      {...rest}
      onClick={(e) => {
        if (!onClick) {
          return;
        }

        if (disabled || _disabled) {
          e.preventDefault();
          return;
        }

        _setDisabled(true);
        onClick(e);
        _setDisabled(false);
      }}
      aria-disabled={disabled || _disabled}
    >
      <FontAwesomeIcon icon={icon} /> {label}
    </StyledPageOptionsItem>
  );
};

export default PageOptionsItem;
