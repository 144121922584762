import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  selectCurrentTool,
  selectTool,
  setStroke,
  setStrokeWidth,
} from "../../../../features/flipbook/toolbar/flipbookToolbarSlice";
import {
  saveAsync,
  selectFlipbook,
  selectLayer,
  selectPage,
  updateLayer,
} from "../../../../features/flipbook/editor/flipbookEditorSlice";
import { ToolType } from "../../../core/flipbook";

export const useToolbar = () => {
  const tool = useAppSelector(selectCurrentTool);
  const flipbook = useAppSelector(selectFlipbook);
  const page = useAppSelector(selectPage);
  const layer = useAppSelector(selectLayer);

  const dispatch = useAppDispatch();

  function _setTool(toolType: ToolType) {
    dispatch(selectTool(toolType));
  }

  function _setStrokeWidth(strokeWidth: number) {
    dispatch(setStrokeWidth(strokeWidth));
  }

  function _setStroke(stroke?: string) {
    dispatch(setStroke(stroke));
  }

  function _clear() {
    if (!flipbook || !page || !layer) {
      return;
    }
    // clear to store
    dispatch(updateLayer({ lines: [] }));

    // persist in firestore
    dispatch(saveAsync());
  }

  return {
    tool,
    setTool: _setTool,
    setStrokeWidth: _setStrokeWidth,
    setStroke: _setStroke,
    clear: _clear,
  };
};

export default useToolbar;
