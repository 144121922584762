import { Container, Row } from "react-bootstrap";

export const ResetConfirmation: React.FunctionComponent = () => {
  return (
    <Container>
      <Row>
        <h1>Password reset sent.</h1>
      </Row>
    </Container>
  );
};

export default ResetConfirmation;
