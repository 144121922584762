import {
  Timestamp,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import { FlipbookMetadata, flipbooksMetadataCol } from "../../../core/flipbook";
import { FlipbookSharedProps, IFlipbooks } from "../FlipbookApi.types";

export class FlipbooksApi implements IFlipbooks {
  private _uid: string;

  constructor({ uid }: FlipbookSharedProps) {
    this._uid = uid;
  }

  public async fetch(
    requestLimit: number = 9,
    lastVisible?: { created: string }
  ): Promise<FlipbookMetadata[]> {
    const q = lastVisible
      ? query(
          flipbooksMetadataCol(this._uid),
          orderBy("created", "desc"),
          startAfter(Timestamp.fromDate(new Date(lastVisible.created))),
          limit(requestLimit)
        )
      : query(
          flipbooksMetadataCol(this._uid),
          orderBy("created", "desc"),
          limit(requestLimit)
        );

    const flipbookSnapshots = await getDocs(q);
    const flipbooks: FlipbookMetadata[] = flipbookSnapshots.docs.map((d) => {
      return {
        ...d.data(),
        created: d.data().created.toDate().toISOString(),
        edited: d.data().edited.toDate().toISOString(),
      };
    });

    return flipbooks;
  }
}

export default FlipbooksApi;
