import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../..";
import {
  flipbookApi,
  resetFlipbookApi,
  setFlipbookApi,
} from "../setupFlipbookApi";
import FlipbookApi from "../flipbookApi";

export const useFlipbookApi: () => void = () => {
  const [user, loading] = useAuthState(auth);

  React.useEffect(() => {
    if (loading) {
      resetFlipbookApi();
      return;
    }

    if (!user) {
      resetFlipbookApi();
      return;
    }

    if (flipbookApi) {
      return;
    }

    setFlipbookApi(new FlipbookApi(user.uid));
  }, [user, loading]);
};

export default useFlipbookApi;
