import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { Page } from "../../../library/core/flipbook";

export interface FlipbookClipboardState {
  page?: Page | undefined;
}

const initialState: FlipbookClipboardState = {};

export const flipbookClipboardSlice = createSlice({
  name: "flipbookClipboard",
  initialState,
  reducers: {
    copyPage: (state: FlipbookClipboardState, action: PayloadAction<Page>) => {
      state.page = action.payload;
    },
    clearPageClipboard: (state: FlipbookClipboardState) => {
      state.page = undefined;
    },
  },
});

export const { copyPage, clearPageClipboard } = flipbookClipboardSlice.actions;

/** SELECTORS */
export const selectPageClipboard = (state: RootState): Page | undefined =>
  state.flipbookClipboard.page;

export default flipbookClipboardSlice.reducer;
