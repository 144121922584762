import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { AllTools, Pen, Tool, ToolType } from "../../../library/core/flipbook";

export interface FlipbookToolbarState {
  tools: Tool[];
  selectedTool: ToolType;
}

const initialState: FlipbookToolbarState = {
  tools: AllTools,
  selectedTool: "Pen",
};

export const flipbookToolbarSlice = createSlice({
  name: "flipbookToolbar",
  initialState,
  reducers: {
    selectTool: (
      state: FlipbookToolbarState,
      action: PayloadAction<ToolType>
    ) => {
      state.selectedTool = action.payload;
    },
    setStrokeWidth: (
      state: FlipbookToolbarState,
      action: PayloadAction<number>
    ) => {
      const tool = _currTool(state);

      if (!tool) {
        return;
      }

      tool.strokeWidth = action.payload;
    },
    setStroke: (
      state: FlipbookToolbarState,
      action: PayloadAction<string | undefined>
    ) => {
      const tool = _currTool(state);

      if (!tool) {
        return;
      }

      tool.stroke = action.payload;
    },
  },
});

export const { selectTool, setStrokeWidth, setStroke } =
  flipbookToolbarSlice.actions;

/** SELECTORS */
export const selectCurrentTool = (state: RootState) =>
  _currTool(state.flipbookToolbar) || Pen;

const _currTool = (state: FlipbookToolbarState): Tool | undefined => {
  return state.tools.find((x) => x.type === state.selectedTool);
};

export default flipbookToolbarSlice.reducer;
