import { FunctionComponent } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { updateLayerIndex } from "../../../../features/flipbook/editor/flipbookEditorSlice";
import Canvas from "../../Canvas";
import { ILayerProps } from "../Layers.types";

export const Layer: FunctionComponent<ILayerProps> = ({
  layer,
  ...rest
}: ILayerProps) => {
  const dispatch = useAppDispatch();
  const selectLayer = () => {
    dispatch(updateLayerIndex(layer.index));
  };

  return (
    <div onClick={selectLayer} aria-hidden="true">
      <Canvas
        {...rest}
        layers={[layer]}
        style={{ border: "1px solid black" }}
      />
      {layer.index}
    </div>
  );
};

export default Layer;
