import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  saveAsync,
  selectFlipbook,
  selectLayer,
  selectPage,
  updateLayer,
} from "../../../../features/flipbook/editor/flipbookEditorSlice";
import { Layer } from "../../../core/flipbook";

export const useLayer = () => {
  const flipbook = useAppSelector(selectFlipbook);
  const page = useAppSelector(selectPage);
  const layer = useAppSelector(selectLayer);
  const dispatch = useAppDispatch();

  function _updateLayer(layerUpdates: Partial<Layer>) {
    if (!flipbook || !page || !layer) {
      return;
    }

    dispatch(
      updateLayer({
        ...layerUpdates,
      })
    );

    dispatch(saveAsync());
  }

  return { layer, updateLayer: _updateLayer };
};

export default useLayer;
