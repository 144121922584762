import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";
import { useFlipbookPreview } from "../hooks/useFlipbookPreview";
import Preview from "./Preview";

const FlipbookPreview: FunctionComponent = () => {
  const { loading, selected, flipbook } = useFlipbookPreview();

  if (!selected) {
    return (
      <Navigate
        to="/flipbooks"
        state={{
          toast: {
            header: "Error",
            body: "An error occurred opening your flipbook",
          },
        }}
      />
    );
  }

  if (loading) {
    return <div>Loading</div>;
  }

  if (!flipbook) {
    return (
      <Navigate
        to="/flipbooks"
        state={{
          toast: {
            header: "Error",
            body: "An error occurred opening your flipbook",
          },
        }}
      />
    );
  }

  return <Preview />;
};

export default FlipbookPreview;
