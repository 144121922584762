import styled from "styled-components";
import {
  BUTTON_HEIGHT,
  BUTTON_WIDTH,
  PADDING,
  SIDEBAR_WIDTH,
} from "../../../core/styles";
import { EditorLocation } from "../EditorPanel.types";

export const StyledEditorPanelItemOptions = styled.div<{
  location: EditorLocation;
}>`
  position: fixed;
  background-color: var(--bs-light);
  padding: ${PADDING};

  ${({ location }) => {
    switch (location) {
      case "TOP_RIGHT":
        return `top: ${PADDING}; right: calc(${PADDING} + ${BUTTON_WIDTH} + ${PADDING})`;
      case "BOTTOM_LEFT":
        return `bottom: calc(${PADDING} + ${BUTTON_HEIGHT} + ${PADDING}); 
        max-width: calc(100% - ${PADDING} - ${PADDING} - ${SIDEBAR_WIDTH});`;
      default:
        return ``;
    }
  }}
`;
