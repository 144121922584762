import {
  faCircleArrowLeft,
  faCircleArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IArrowProps } from "../HorizontalScroll.types";

export const Arrow: React.FunctionComponent<IArrowProps> = ({
  direction,
  size,
  ...rest
}: IArrowProps) => {
  return (
    <FontAwesomeIcon
      icon={direction === "left" ? faCircleArrowLeft : faCircleArrowRight}
      size={size === undefined ? "2x" : size}
      color={`var(--bs-primary)`}
      {...rest}
    />
  );
};
