import { FunctionComponent } from "react";
import useLayer from "../hooks/useLayer";

export const LayerOptions: FunctionComponent = () => {
  const { layer, updateLayer } = useLayer();

  if (!layer) {
    return null;
  }

  return (
    <div>
      <input
        type="number"
        min="0"
        max="100"
        step="1"
        value={layer.opacity ? layer.opacity * 100 : 100}
        onChange={(e) => {
          let opacity = Number(e.target.value) / 100;

          if (opacity < 0) {
            opacity = 0;
          }

          if (opacity > 1) {
            opacity = 1;
          }

          updateLayer({
            opacity: opacity,
          });
        }}
      />
    </div>
  );
};

export default LayerOptions;
