import { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";

interface ISidebarContainerProps {
  children?: ReactNode;
}

const StyledSidebarContainer = styled.div<ISidebarContainerProps>`
  margin-bottom: 1em;
`;

const SidebarContainer: FunctionComponent<ISidebarContainerProps> = ({
  children,
}: ISidebarContainerProps) => {
  return <StyledSidebarContainer>{children}</StyledSidebarContainer>;
};

export default SidebarContainer;
