import "./App.scss";
import { Link, Route, Routes } from "react-router-dom";
import Login from "./features/auth/components/Login";
import Register from "./features/auth/components/Register";
import Reset from "./features/auth/components/Reset";
import Layout from "./features/layout/Layout";
import FlipbookCreate from "./features/flipbook/creation/components/FlipbookCreate";
import { default as Auth } from "./library/controls/RequireAuthOrRedirect";
import { default as UnAuth } from "./library/controls/RequireUnAuthOrRedirect";
import FlipbookEditor from "./features/flipbook/editor/components/FlipbookEditor";
import { useFlipbookApi } from "./library/apis/flipbook";
import FlipbookDashboard from "./features/flipbook/dashboard/components/FlipbookDashboard";
import ResetConfirmation from "./features/auth/components/ResetConfirmation";
import FlipbookPreview from "./features/flipbook/preview/components/FlipbookPreview";

function App() {
  useFlipbookApi();

  return (
    <div className="App">
      <Routes>
        <Route
          path="/flipbooks/editor"
          element={
            <Auth>
              <FlipbookEditor />
            </Auth>
          }
        />
        <Route
          path="/flipbooks/preview"
          element={
            <Auth>
              <FlipbookPreview />
            </Auth>
          }
        />
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route
            path="login"
            element={
              <UnAuth>
                <Login />
              </UnAuth>
            }
          />
          <Route
            path="register"
            element={
              <UnAuth>
                <Register />
              </UnAuth>
            }
          />
          <Route
            path="reset"
            element={
              <UnAuth>
                <Reset />
              </UnAuth>
            }
          />
          <Route
            path="reset-confirmation"
            element={
              <UnAuth>
                <ResetConfirmation />
              </UnAuth>
            }
          />

          <Route
            path="flipbooks"
            element={
              <Auth>
                <FlipbookDashboard />
              </Auth>
            }
          />
          <Route
            path="flipbooks/create"
            element={
              <Auth>
                <FlipbookCreate />
              </Auth>
            }
          />

          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
