import React, { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { logInWithEmailAndPassword } from "../../../library/apis/auth";

import { SchemaOf, object, string } from "yup";
import { Formik, FormikHelpers } from "formik";
import {
  Alert,
  Button,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";

interface FormValues {
  email: string;
  password: string;
}

const LoginSchema: SchemaOf<FormValues> = object({
  email: string()
    .required("Required")
    .email("Please enter a valid email address"),
  password: string().required("Required"),
});

export const Login: React.FunctionComponent = () => {
  const [formError, setFormError] = useState<ReactNode>(null);

  const handleSubmit = async (
    { email, password }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setFormError(null);
      await logInWithEmailAndPassword(email, password);
    } catch (e) {
      setFormError(
        <div>
          An error occurred trying to login... Please try again later or contact
          support.
        </div>
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container>
      <Row>
        <h1>Login</h1>

        {formError && <Alert variant="danger">{formError}</Alert>}

        <Formik<FormValues>
          initialValues={{ email: "", password: "" }}
          validationSchema={LoginSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <Form.Group className="mb-3">
                <FloatingLabel label="Email">
                  <Form.Control
                    type="email"
                    placeholder="E-mail Address"
                    size="sm"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    isInvalid={touched.email && !!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3">
                <FloatingLabel label="Password">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    size="sm"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Logging in" : "Login"}
              </Button>

              <div>
                <Link to="/reset">Forgot Password</Link>
              </div>
              <div>
                Don&apos;t have an account? <Link to="/register">Register</Link>{" "}
                now.
              </div>
            </Form>
          )}
        </Formik>
      </Row>
    </Container>
  );
};

export default Login;
